import React from "react";

const InstagramIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      version="1"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M1609 5110c-330-14-556-56-747-138-179-77-285-148-417-279-178-177-289-376-359-643-62-236-76-507-76-1490 0-967 13-1241 71-1477C225 496 655 122 1288 35c181-24 561-35 1287-35 675 0 1064 10 1239 31 358 42 638 166 859 378 249 240 381 527 429 936 19 160 19 2294 0 2445-39 306-130 558-274 751-71 96-223 241-318 306-215 145-474 225-815 254-161 13-1813 20-2086 9zm2163-483c285-42 445-113 599-266 100-100 155-188 204-324 82-230 90-352 90-1482 0-651-4-964-13-1069-29-347-98-534-260-707-169-180-380-269-707-299-177-17-1640-25-1977-11-152 6-311 16-354 22-189 26-372 93-486 176-71 53-185 177-227 247-84 140-134 337-151 587-30 454-20 2012 14 2270 36 267 119 447 276 599 172 167 398 248 748 270 76 4 587 7 1137 5 853-2 1016-4 1107-18z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
      <path
        d="M3802 4200c-113-57-167-145-167-275 0-94 22-150 86-214 64-63 120-86 214-86 67 0 89 4 137 28 101 50 158 137 166 253 7 103-16 163-87 235-66 66-124 89-221 89-54 0-78-6-128-30zM2336 3854c-263-48-496-171-692-368-260-259-385-562-385-926 0-220 41-401 132-583 280-556 896-841 1495-692 236 59 418 161 595 332 159 154 266 322 333 523 48 145 66 257 66 420 0 162-18 275-65 417-64 192-157 344-302 494-198 204-445 336-717 384-111 19-352 19-460-1zm396-458c458-88 762-538 674-999-54-279-259-527-521-628-128-50-202-62-350-56-105 4-146 10-215 32-303 96-524 341-586 649-24 119-15 304 21 416 94 298 345 525 644 585 89 18 243 18 333 1z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
    </svg>
  );
}

export default InstagramIcon;
