import { useDispatch, useSelector } from "react-redux";
import {
  updateServerCategories,
  updateActiveServerCategory,
  updateActiveServerTag,
  updateActiveServerSearch,
  updateActiveServerOrder,
  updateServerTags
} from "../../store/navigationReducer";
import { getServerCategories } from "../../api/api";

const useServerCategories = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.navigation.serverCategories);
  const tags = useSelector((state) => state.navigation.serverTags);
  const activeCategory = useSelector((state) => state.navigation.activeServerCategory);
  const activeTag = useSelector((state) => state.navigation.activeServerTag);
  const activeSearch = useSelector((state) => state.navigation.activeServerSearch);
  const activeOrder = useSelector((state) => state.navigation.activeServerOrder);

  const changeServerCategories = (value) => {
    dispatch(updateServerCategories(value));
  }

  const changeServerTags = (value) => {
    dispatch(updateServerTags(value));
  }

  const changeActiveServerCategory = (value) => {
    dispatch(updateActiveServerCategory(value));
  }

  const changeActiveServerTag = (value) => {
    dispatch(updateActiveServerTag(value));
  }

  const changeActiveServerSearch = (value) => {
    dispatch(updateActiveServerSearch(value));
  }

  const changeActiveServerOrder = (value) => {
    dispatch(updateActiveServerOrder(value));
  }

  const fetch = async () => {
    const categories = await getServerCategories();
    changeServerCategories(categories?.data);
    if (categories?.data) {
      const allTags = categories.data.flatMap(category => category.tags);
      changeServerTags(Array.from(new Set(allTags.map(tag => tag.id))).map(id => allTags.find(tag => tag.id === id)));
    }
  }

  return {
    fetch,
    changeActiveServerCategory,
    changeActiveServerTag,
    changeActiveServerSearch,
    changeActiveServerOrder,
    categories,
    tags,
    activeCategory,
    activeTag,
    activeSearch,
    activeOrder,
  }

};

export default useServerCategories;
