import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Chip,
  Checkbox,
  Input,
  Typography,
  useTheme,
  Autocomplete,
  Select,
  MenuItem,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import useServerCategories from "../hooks/useServerCategories";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import useSnackbar from "../hooks/useSnackbar";
import useServers from "../hooks/useServers";
import { CREATE_MODE, EDIT_MODE } from "../../constants";
import { buildDynamicFields, getValidationSchema } from "./helpers/serverHelpers";
import MuiEditor from "../MuiEditor";
import { InputField } from "./fields/serverFields";
import _ from "lodash";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const statusOptions = [
  { label: 'Опубліковано', value: 2 },
  { label: 'Приховано', value: 3 },
];

const initialValues = {
  name: '',
  short_description: '',
  description: '',
  category: null,
  tags: [],
  logo: null,
  banner: null,
  status: null,
  main_ip: null,
  query_port: null,
  additional_ip: null,
  version_from: null,
  version_to: null,
  discord_url: null,
  map_url: null,
  website_url: null,
  youtube_url: null,
  twitch_url: null,
  instagram_url: null,
  monobase_url: null,
  monojar_url: null,
  donatello_url: null,
  diaka_url: null,
};

const ServerForm = ({ onSave, reset, server, mode }) => {
  const { categories } = useServerCategories();
  const { serverLoading } = useServers();
  const { setConfig } = useSnackbar();
  const theme = useTheme();
  const formikRef = useRef(null);
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [dynamicFieldsConfig, setDynamicFieldsConfig] = useState(undefined);

  useEffect(() => {
    if (selectedCategory) {
      setDynamicFieldsConfig(selectedCategory.config?.dynamic_fields);
    } else setDynamicFieldsConfig(undefined);
  }, [selectedCategory]);

  useEffect(() => {
    setSelectedCategory(categories.find(c => c.slug === server?.category.slug));
  }, [server]);

  const handleSubmit = async (values, { resetForm, setErrors }) => {
    try {
      const formData = new FormData();
      const data = { ...values, category: { id: values.category.id } };
      const serverId = data.id;
      delete data.id;
      delete data.mode;
      Object.keys(data).forEach(key => {
        const value = data[key];
        if (value === null || value === undefined) return;

        if (value instanceof File) {
          formData.append(key, value);
        } else if (Array.isArray(value)) {
          formData.append(key, JSON.stringify(value));
        } else if (typeof value === 'object') {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      });

      const response = await onSave(formData, serverId);
      if (!response?.id) {
        const [[, firstValue]] = Object.entries(response);
        if (typeof firstValue === 'string') {
          setErrors(response);
          setConfig({
            severity: 'error', text: firstValue,
          })
        } else if (Array.isArray(firstValue)) {
          setErrors(response);
          setConfig({
            severity: 'error', text: _.head(firstValue),
          })
        } else {
          setConfig({
            severity: 'error', text: mode === CREATE_MODE ? 'Помилка створення серверу' : 'Помилка оновлення серверу',
          })
          resetForm({ values });
        }
      } else {
        setConfig({
          severity: 'success', text: mode === CREATE_MODE ? 'Сервер успішно створено' : 'Сервер успішно оновлено',
        })
        resetForm();
      }
    } catch (error) {
      setConfig({
        severity: 'error',
        text: 'Щось пішло не так',
      });
    }
  };

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  }, [reset]);

  const isOptionEqualToValue = (option, value) => {
    return option.id === value.id;
  };

  const validate = async (values, config) => {
    const validationSchema = getValidationSchema(config);
    const errors = {};

    try {
      await validationSchema.validate(values, { abortEarly: false });
    } catch (yupErrors) {
      yupErrors.inner.forEach((err) => {
        errors[err.path] = err.message;
      });
    }

    return errors;
  };

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      validateOnBlur={false}
      initialValues={server || initialValues}
      validationSchema={getValidationSchema(dynamicFieldsConfig)}
      validate={(values) => validate(values, dynamicFieldsConfig)}
      onSubmit={handleSubmit}
    >
      {({ values, resetForm, setFieldValue, errors, touched, dirty, isSubmitting, isValidating }) => (
        <Form
          style={{
            display: 'flex',
            width: '100%',
            maxWidth: 900,
            marginTop: 16,
            marginLeft: 8,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              backgroundColor: theme.palette.background.paper,
              borderRadius: 3,
              gap: 2,
              flex: 1,
              p: 3,
            }}
          >
            <Box>
              <Typography
                variant="h3"
                component="h3"
                sx={{
                  fontSize: 20
                }}
              >
                Загальні відомості
              </Typography>
            </Box>
            <Box>
              <Field
                as={TextField}
                name="name"
                label="Назва"
                fullWidth
                InputProps={{
                  placeholder: 'Назва'
                }}
                InputLabelProps={{
                  required: true
                }}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
            </Box>

            <Box>
              <Field
                as={TextField}
                name="short_description"
                label="Короткий опис"
                multiline
                rows={3}
                fullWidth
                InputProps={{
                  placeholder: 'Короткий опис'
                }}
                InputLabelProps={{
                  required: true
                }}
                error={touched.short_description && Boolean(errors.short_description)}
                helperText={touched.short_description && errors.short_description}
              />
            </Box>

            <Box>
              <MuiEditor
                label='Детальний опис'
                placeholder='Детальний опис'
                touched={touched.description}
                error={errors.description}
                required
                mode={mode}
                onChange={(newValue) => setFieldValue('description', newValue)}
                value={values.description}
              />
            </Box>

            <Box>
              <Autocomplete
                id="category"
                freeSolo
                options={categories}
                isOptionEqualToValue={isOptionEqualToValue}
                getOptionLabel={(option) => option.name}
                getOptionKey={(option) => option.slug}
                value={values.category}
                onChange={(event, newValue) => {
                  setSelectedCategory(newValue);
                  setFieldValue('category', newValue);
                  setFieldValue('tags', []);
                }}
                disabled={mode === EDIT_MODE}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Категорія"
                    fullWidth
                    InputLabelProps={{
                      required: mode === CREATE_MODE
                    }}
                    disabled={mode === EDIT_MODE}
                    placeholder='Категорія серверу'
                    error={touched.category && Boolean(errors.category)}
                    helperText={touched.category && errors.category?.name}
                  />
                )}
              />
            </Box>

            <Box>
              <Autocomplete
                id="tags"
                multiple
                disableCloseOnSelect
                options={values.category ? categories.find(c => c.slug === values.category.slug).tags : []}
                getOptionKey={(option) => option.slug}
                getOptionLabel={(option) => option.name}
                value={values.tags}
                isOptionEqualToValue={isOptionEqualToValue}
                onChange={(event, newValue) => setFieldValue('tags', newValue)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={option.slug}
                      label={option.name}
                    />
                  ))
                }
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Теги"
                    fullWidth
                    placeholder="Теги серверу"
                    InputLabelProps={{
                      required: true
                    }}
                    error={touched.tags && Boolean(errors.tags)}
                    helperText={touched.tags && errors.tags}
                  />
                )}
                disabled={!values.category}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 2,
              }}
            >
              {buildDynamicFields(dynamicFieldsConfig, values, setFieldValue, touched, errors)}
            </Box>

            <Box>
              <Typography
                variant="h3"
                component="h3"
                sx={{
                  fontSize: 20,
                  mt: 1
                }}
              >
                Соціальні мережі
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 2,
              }}
            >
              {!dynamicFieldsConfig?.discord_invite ? (
                <InputField
                  name="discord_url"
                  label="Дискорд"
                  placeholder="Дискорд"
                  value={values.discord_url}
                  touched={touched.discord_url}
                  error={errors.discord_url}
                  setFieldValue={setFieldValue}
                />
              ) : null}
              <InputField
                name="youtube_url"
                label="Youtube"
                placeholder="Youtube"
                value={values.youtube_url}
                touched={touched.youtube_url}
                error={errors.youtube_url}
                setFieldValue={setFieldValue}
              />
              <InputField
                name="twitch_url"
                label="Twitch"
                placeholder="Twitch"
                value={values.twitch_url}
                touched={touched.twitch_url}
                error={errors.twitch_url}
                setFieldValue={setFieldValue}
              />
              <InputField
                name="instagram_url"
                label="Instagram"
                placeholder="Instagram"
                value={values.instagram_url}
                touched={touched.instagram_url}
                error={errors.instagram_url}
                setFieldValue={setFieldValue}
              />
              <InputField
                name="website_url"
                label="Вебсайт"
                placeholder="Вебсайт"
                value={values.website_url}
                touched={touched.website_url}
                error={errors.website_url}
                setFieldValue={setFieldValue}
              />
            </Box>

            <Box>
              <Typography
                variant="h3"
                component="h3"
                sx={{
                  fontSize: 20,
                  mt: 1,
                }}
              >
                Підтримати
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 2,
              }}
            >
              <InputField
                name="monojar_url"
                label="Монобанка"
                placeholder="Монобанка"
                value={values.monojar_url}
                touched={touched.monojar_url}
                error={errors.monojar_url}
                setFieldValue={setFieldValue}
              />
              <InputField
                name="monobase_url"
                label="Монобаза"
                placeholder="Монобаза"
                value={values.monobase_url}
                touched={touched.monobase_url}
                error={errors.monobase_url}
                setFieldValue={setFieldValue}
              />
              <InputField
                name="donatello_url"
                label="Донателло"
                placeholder="Донателло"
                value={values.donatello_url}
                touched={touched.donatello_url}
                error={errors.donatello_url}
                setFieldValue={setFieldValue}
              />
              <InputField
                name="diaka_url"
                label="Дяка"
                placeholder="Дяка"
                value={values.diaka_url}
                touched={touched.diaka_url}
                error={errors.diaka_url}
                setFieldValue={setFieldValue}
              />
            </Box>

            <Box
              sx={{
                mt: 1,
                display: 'flex',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                gap: 1
              }}
            >
              <Box
                sx={{
                  flexBasis: 150,
                  minWidth: 100,
                }}
              >
                <Typography variant="subtitle1">Логотип</Typography>
                <Button
                  variant="outlined"
                  component="label"
                  sx={{
                    mt: 0.5,
                    borderRadius: 3,
                    color: theme.palette.text.primary,
                  }}
                >
                  Вибрати файл
                  <Input
                    type="file"
                    name="logo"
                    accept="image/webp, image/png, image/svg"
                    onChange={(event) => setFieldValue('logo', event.currentTarget.files[0])}
                    sx={{ display: 'none' }}
                  />
                </Button>
                {values.logo && (
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
                    {values.logo.name}
                  </Typography>
                )}
                {touched.logo && errors.logo && (
                  <Typography variant="body2" color="error" sx={{ mt: 0.5 }}>
                    {errors.logo}
                  </Typography>
                )}
              </Box>

              <Box
                sx={{
                  flexBasis: 150,
                  minWidth: 100,
                }}
              >
                <Typography variant="subtitle1">Банер</Typography>
                <Button
                  variant="outlined"
                  component="label"
                  sx={{
                    mt: 0.5,
                    borderRadius: 3,
                    color: theme.palette.text.primary,
                  }}
                >
                  Вибрати файл
                  <Input
                    type="file"
                    name="banner"
                    accept="image/webp, image/png, image/svg"
                    onChange={(event) => setFieldValue('banner', event.currentTarget.files[0])}
                    sx={{ display: 'none' }}
                  />
                </Button>
                {values.banner && (
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
                    {values.banner.name}
                  </Typography>
                )}
                {touched.banner && errors.banner && (
                  <Typography variant="body2" color="error" sx={{ mt: 0.5 }}>
                    {errors.banner}
                  </Typography>
                )}
              </Box>

              {mode === EDIT_MODE && [2, 3].includes(values.status) ? (
                <Box
                  sx={{
                    flexBasis: { xs: '100%', sm: 200 },
                    minWidth: 200,
                  }}
                >
                  <Select
                    name="status"
                    value={values.status}
                    sx={{
                      width: '100%',
                    }}
                    onChange={(event) => setFieldValue('status', event.target.value)}
                    error={touched.status && Boolean(errors.status)}
                  >
                    {statusOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.status && errors.status && (
                    <Typography color="error" variant="caption">
                      {errors.status}
                    </Typography>
                  )}
                </Box>
              ) : null}

              <Box
                sx={{
                  flexBasis: 200,
                  flexGrow: 1,
                  minWidth: 200,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 1,
                }}
              >
                {mode === EDIT_MODE ? (
                  <Button
                    variant="contained"
                    onClick={() => resetForm({ values: server || initialValues })}
                    disabled={serverLoading || !dirty || isSubmitting || isValidating}
                    sx={{
                      height: 'fit-content',
                      width: 'fit-content',
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.text.primary,
                      borderRadius: 3,
                      ml: 'auto',
                      mt: 1,
                      mr: 1,
                    }}
                  >
                    Відмінити
                  </Button>
                ) : null}
                <Button
                  type="submit"
                  variant="contained"
                  disabled={serverLoading || !dirty || isSubmitting || isValidating}
                  sx={{
                    height: 'fit-content',
                    width: 'fit-content',
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.text.primary,
                    borderRadius: 3,
                    mt: 1,
                  }}
                >
                  {mode === CREATE_MODE ? 'Створити' : 'Оновити'}
                </Button>
              </Box>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ServerForm;
