import React from "react";

const ThemeIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      version="1"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M2285 4675c-892-117-1619-787-1809-1665-56-261-61-585-12-843 162-851 820-1520 1671-1698 152-32 304-33 360-2 53 28 121 102 148 161 21 46 22 61 27 534l5 488 28 27 27 28 773 5c729 5 775 6 822 24 151 58 254 161 295 294 59 190 85 523 61 754-81 745-540 1390-1219 1710-197 93-445 163-667 188-122 13-387 11-510-5zm-226-869c64-33 114-83 148-149 23-45 27-64 27-137s-4-92-27-137c-34-66-84-116-150-150-45-23-64-27-137-27s-92 4-137 27c-66 34-116 84-150 150-23 45-27 64-27 137s4 92 27 137c45 86 123 151 212 174 55 15 159 2 214-25zm1280 0c64-33 114-83 148-149 23-45 27-64 27-137s-4-92-27-137c-34-66-84-116-150-150-45-23-64-27-137-27s-92 4-137 27c-66 34-116 84-150 150-23 45-27 64-27 137s4 92 27 137c45 86 123 151 212 174 55 15 159 2 214-25zm-1812-856c237-116 236-453-3-571-41-20-64-24-139-24s-98 4-135 24c-61 32-116 86-148 146-23 43-27 62-27 140 0 106 22 163 89 228 103 99 233 120 363 57zm2339 6c64-30 123-87 155-152 20-41 24-64 24-139 0-78-4-97-27-140-32-60-87-114-148-146-65-34-204-34-272-1-191 94-242 336-104 500 87 102 246 135 372 78z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
    </svg>
  );
}

export default ThemeIcon;
