import React from "react";

const TwitchIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      version="1"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M376 4677l-166-444V670h1220V0h675l335 335 335 335h997l669 661 669 662v3127H543l-167-443zm4292-1227l2-1225-387-387-388-388H2665l-330-330c-181-182-333-330-337-330-5 0-8 149-8 330v330H990v3230l1838-2 1837-3 3-1225z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
      <path
        d="M2220 3115v-665h440v1330h-440v-665zM3440 3115v-665h450v1330h-450v-665z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
    </svg>
  );
}

export default TwitchIcon;
