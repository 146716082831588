import React, { useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  Button,
  Box,
  Rating,
  useTheme,
} from '@mui/material';
import _ from "lodash";
import useSnackbar from "../hooks/useSnackbar";
import useCommunications from "../hooks/useCommunications";
import useServers from "../hooks/useServers";
import ConfirmationDialog from "../modals/ConfirmationDialog";

const validationSchema = Yup.object({
  text: Yup.string()
    .required("Відгук обов'язковий")
    .min(4, 'Мінімум 4 символи')
    .max(256, 'Максимум 256 символів'),
  rating: Yup.number().nullable().required("Рейтинг обов'язковий")
});

const initialValues = {
  text: '',
  rating: null,
  parent: null,
};

const CommentForm = ({ comment, isEdit, handleReset }) => {
  const theme = useTheme();
  const { setConfig } = useSnackbar();
  const { selectedServer, changeSelectedServer } = useServers();
  const { commentsLoading, addComment, editComment, deleteComment } = useCommunications();
  const formikRef = useRef(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

  const handleSubmit = async (values, { resetForm, setErrors }) => {
    try {
      const data = {
        text: values.text,
        rating: values.rating,
      };
      let response;
      if (isEdit) {
        response = await editComment(comment.id, data)
      } else {
        response = await addComment(selectedServer.id, data)
      }
      if (!response?.id) {
        const [[, firstValue]] = Object.entries(response);
        if (typeof firstValue === 'string') {
          setErrors(response);
          setConfig({
            severity: 'error', text: firstValue,
          })
        } else if (Array.isArray(firstValue)) {
          setErrors(response);
          setConfig({
            severity: 'error', text: _.head(firstValue),
          })
        } else {
          setConfig({
            severity: 'error', text: isEdit ? 'Помилка при редагуванні відгуку' : 'Помилка при додаванні відгуку',
          })
          resetForm({ values });
        }
      } else {
        setConfig({
          severity: 'success',
          text: isEdit ? 'Відгук успішно змінено' : 'Відгук успішно додано',
        });
        resetForm({ values });
        if (response?.count) {
          changeSelectedServer({ ...selectedServer, comments: response?.count })
        }
      }
    } catch (error) {
      setConfig({
        severity: 'error',
        text: 'Щось пішло не так',
      });
    }
  };

  const handleDelete = async () => {
    try {
      const response = await deleteComment(comment.id)
      if (!response?.id) {
        const [[, firstValue]] = Object.entries(response);
        if (typeof firstValue === 'string') {
          setConfig({
            severity: 'error', text: firstValue,
          })
        } else if (Array.isArray(firstValue)) {
          setConfig({
            severity: 'error', text: _.head(firstValue),
          })
        } else {
          setConfig({
            severity: 'error', text: 'Помилка при видаленні відгуку',
          })
        }
      } else {
        setConfig({
          severity: 'success',
          text: 'Відгук успішно видалено',
        });
        handleReset();
        formikRef.current.resetForm();
        if (response?.count) {
          changeSelectedServer({ ...selectedServer, comments: response?.count })
        }
      }
    } catch (error) {
      setConfig({
        severity: 'error',
        text: 'Щось пішло не так',
      });
    } finally {
      setIsDeleteConfirmationOpen(false);
    }
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={comment || initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isSubmitting,
          dirty,
          isValidating,
          setFieldValue,
        }) => (
          <Form>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.background.paper,
                py: 2,
                borderRadius: 2,
                gap: 2,
              }}
            >

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Rating
                  name="rating"
                  value={values.rating}
                  onChange={(event, newValue) => setFieldValue("rating", newValue)}
                  precision={1}
                  max={5}
                  sx={{
                    fill: theme.palette.text.secondary
                  }}
                />
                {touched.rating && errors.rating && (
                  <Box sx={{ color: 'error.main' }}>
                    {errors.rating}
                  </Box>
                )}
              </Box>

              <TextField
                name="text"
                label="Ваш відгук"
                multiline
                rows={4}
                value={values.text}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                error={touched.text && Boolean(errors.text)}
                helperText={touched.text && errors.text}
              />

              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  mt: 1,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={commentsLoading || !dirty || isSubmitting || isValidating}
                  sx={{
                    height: 'fit-content',
                    width: 'fit-content',
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.text.primary,
                    borderRadius: 3,
                  }}
                >
                  {isEdit ? "Зберегти" : "Надіслати"}
                </Button>
                {isEdit ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsDeleteConfirmationOpen(true)}
                    disabled={commentsLoading || isSubmitting || isValidating}
                    sx={{
                      height: 'fit-content',
                      width: 'fit-content',
                      backgroundColor: theme.palette.secondary.main,
                      color: theme.palette.text.primary,
                      borderRadius: 3,
                    }}
                  >
                    Видалити
                  </Button>
                ) : null}
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      <ConfirmationDialog
        open={isDeleteConfirmationOpen}
        handleClose={() => setIsDeleteConfirmationOpen(false)}
        handleDelete={handleDelete}
        question={"Бажаєте видалити відгук?"}
      />
    </>
  );
};

export default CommentForm;
