import { getTransparentColor } from "../themes";
import { Box } from "@mui/material";
import BoostIcon from "../assets/icons/Boost";
import SponsorIcon from "../assets/icons/Sponsor";

const BenefitsIndicators = ({ theme, server }) => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: getTransparentColor(theme.palette.background.paper, 0.9),
      zIndex: 1,
    }}
  >
    {server.has_boost ? (
      <Box
        sx={{
          position: 'absolute',
          top: { xs: 'auto', sm: 10 },
          left: { xs: 10, sm: 'auto' },
          right: { xs: 'auto', sm: 10 },
          bottom: { xs: 3, sm: 'auto' },
        }}
      >
        <BoostIcon fill={theme.palette.text.secondary} />
      </Box>
    ) : null}
    {server.is_sponsor ? (
      <Box
        sx={{
          position: 'absolute',
          top: 'auto',
          left: 'auto',
          right: 10,
          bottom: 3,
        }}
      >
        <SponsorIcon fill={theme.palette.text.secondary} />
      </Box>
    ) : null}
  </Box>
);

export default BenefitsIndicators;
