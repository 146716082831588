import React from "react";

const SteamIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      version="1"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M2375 5054c-16-2-73-9-125-15-435-49-869-231-1240-518-110-85-327-302-412-412-277-357-445-752-515-1206-23-150-23-536 0-686C195 1490 576 883 1170 485c330-221 669-348 1085-407 135-19 503-16 650 5 373 55 731 192 1035 395 617 413 996 1025 1102 1777 17 119 16 493-1 615-88 635-394 1199-866 1597-357 302-802 502-1265 568-108 16-467 29-535 19zm1278-1058c381-113 637-448 637-835 0-335-182-626-485-775-119-59-197-82-321-94l-79-8-405-293c-223-161-411-299-418-305-7-7-20-44-27-82-38-182-176-359-346-443-358-176-780-5-914 370l-26 74-216 85c-118 46-217 89-219 94-2 6-3 192-2 412l3 402 374-149c342-137 375-149 395-136 58 39 172 69 276 74l105 6 270 383 270 384 7 71c38 363 286 664 631 764 100 29 148 34 277 30 97-2 144-9 213-29z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
      <path
        d="M3253 3722c-209-62-375-237-419-441-42-199 13-378 161-527 86-87 161-131 270-159 77-20 221-19 299 1 188 48 350 203 409 389 31 98 31 252 0 350-53 167-198 316-366 375-88 31-267 37-354 12zm299-240c62-27 143-104 176-167 24-45 27-61 27-155s-3-110-27-155c-32-62-113-139-176-168-72-33-207-31-282 4-246 115-276 452-55 605 29 19 72 42 96 50 62 20 181 13 241-14zM1831 2211c-10-7-8-10 10-16 13-4 73-28 133-53 127-53 194-110 242-207 26-54 29-70 29-160s-3-106-29-160c-75-151-234-237-399-214-30 4-112 30-183 58-72 28-133 51-137 51-14 0 16-44 66-97 99-105 209-153 351-153 144 0 251 46 350 149 252 266 126 703-231 796-63 16-180 20-202 6z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
    </svg>
  );
}

export default SteamIcon;
