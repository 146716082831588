import React from "react";

const BoostIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      version="1"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M2365 4969c-104-82-223-179-264-216l-74-67 34-29c48-40 221-122 304-143 94-24 293-24 390-1 81 20 254 102 304 144l33 29-65 61c-58 54-461 374-469 372-2 0-88-68-193-150zM1776 4397c-316-443-465-950-435-1485 5-91 55-433 161-1107l153-975 905 2 905 3 152 955c97 607 156 1010 163 1107 39 511-116 1054-422 1480l-58 82-73-52c-400-287-932-289-1329-4l-78 56-44-62zm624-1165c30-12 79-40 108-62l52-40 53 40c79 60 145 82 262 88 84 3 110 0 165-18 165-55 286-186 325-352 29-124 11-250-56-388-38-79-54-97-223-263-99-98-259-253-354-344l-172-165-183 175c-450 433-521 508-561 584-43 84-76 205-76 278 0 221 139 412 350 480 68 22 239 15 310-13z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
      <path
        d="M2142 2939c-26-13-55-39-70-62-23-34-27-51-26-107 0-117 22-147 281-397 124-120 229-218 233-218s109 98 233 218c256 247 279 280 281 390 2 114-61 186-168 195-91 7-174-55-192-146l-6-32h-295l-7 32c-24 119-154 181-264 127zM3868 1453l-106-668 51-44c243-208 297-318 297-598V0h300v1627l-212 246c-117 135-215 246-218 246-3 1-53-299-112-666zM928 1856l-218-224V0h300v143c0 161 14 236 62 336 25 53 55 89 155 187 73 72 123 128 123 140 0 30-190 1237-198 1256-6 14-50-27-224-206zM2000 260V0h1140v520H2000V260z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
    </svg>
  );
}

export default BoostIcon;
