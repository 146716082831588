import React from "react";

const SponsorIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      version="1"
      viewBox="0 0 64 64"
      {...props}
    >
      <path
        d="M137 576c-21-8-48-22-59-33-28-24-58-91-58-128 0-40 31-104 64-134l26-25 38 37c21 20 44 37 52 37 16 0 100-82 100-98 0-5 9-12 21-15 11-3 23-14 25-24 5-18 46-43 71-43 8 0 16 7 20 16 4 11-16 38-63 85-37 37-65 72-61 76s39-24 76-61c47-47 74-67 85-63 28 11 18 30-51 99-37 37-64 71-60 75s39-24 76-61c69-69 101-81 101-39 0 19-158 183-176 183-5 0-32-22-59-50-50-51-87-64-113-38-22 22-12 43 48 103 33 33 60 63 60 67 0 12-52 36-90 42-20 3-52 0-73-8z"
        transform="matrix(.1 0 0 -.1 0 64)"
      ></path>
      <path
        d="M396 575c-47-16-202-167-192-184 14-22 43-10 91 39 28 29 58 50 70 50 14 0 55-34 114-94l92-95 20 32c83 138-47 306-195 252zM160 275c-32-33-33-36-17-52 16-17 19-15 54 19 31 31 35 39 24 52-18 22-23 21-61-19zM212 227c-26-27-28-55-6-64 21-8 79 50 71 71-9 23-40 20-65-7zM266 171c-29-30-30-61-3-61 22 0 70 57 64 74-9 24-30 19-61-13zM319 121c-33-34-35-43-13-62 13-11 21-7 51 24 31 30 35 38 24 51-19 22-28 20-62-13z"
        transform="matrix(.1 0 0 -.1 0 64)"
      ></path>
    </svg>
  );
}

export default SponsorIcon;
