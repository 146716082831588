import React from "react";

const YoutubeIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      version="1"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M2115 4394c-858-28-1241-56-1440-105-154-37-279-112-391-232-118-127-171-236-210-426-99-488-99-1675 0-2164 39-189 94-301 210-424s257-203 425-238c346-73 1536-121 2366-96 812 24 1226 57 1410 112 246 73 441 261 525 505 128 371 150 1724 38 2294-40 204-115 349-245 471-114 107-239 172-392 204-168 35-532 66-986 85-254 11-1128 20-1310 14zm980-404c791-24 1198-59 1323-116 101-45 174-121 208-216 27-74 53-229 71-419 22-235 25-1056 5-1304-24-288-53-459-93-537-50-99-152-175-270-202-280-65-1478-110-2274-87-644 19-1091 49-1268 87-183 38-292 152-331 349-88 440-88 1570 0 2010 21 107 57 179 118 241 96 95 184 120 508 148 538 46 1358 65 2003 46z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
      <path
        d="M2050 2549v-811l23 14c12 8 325 189 695 403s671 393 670 397c-2 4-295 176-653 383-357 207-669 387-692 401l-43 25v-812z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
    </svg>
  );
}

export default YoutubeIcon;
