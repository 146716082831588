import React from "react";

const DonatelloIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      version="1"
      viewBox="0 0 180 180"
      {...props}
    >
      <path
        d="M485 1785c-212-17-355-47-416-88-72-48-69-15-69-758 0-750-1-739 70-808 59-57 187-91 452-120 270-30 601 15 797 109 280 134 424 340 467 667 12 95 13 133 4 207-13 99-41 223-67 289-25 65-96 166-160 230-166 164-381 246-720 276-139 13-144 13-358-4zm435-395c162-53 260-163 306-340 22-85 14-256-15-351-41-130-148-236-277-274-99-29-269-38-362-19l-22 4 2 498 3 497 45 7c84 12 250 1 320-22z"
        transform="matrix(.1 0 0 -.1 0 180)"
      ></path>
    </svg>
  );
}

export default DonatelloIcon;
