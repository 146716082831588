import { createSlice } from '@reduxjs/toolkit';

const moniCoreCustomTheme = localStorage.getItem('moniCoreCustomTheme');

const session = createSlice({
  name: 'session',
  initialState: {
    activeUser: undefined,
    isAuthenticated: false,
    isAdmin: false,
    activeUserLoading: false,
    userServers: false,
    userServersLoading: undefined,
    theme: localStorage.getItem('moniCoreTheme') || 'core',
    customTheme: moniCoreCustomTheme ? JSON.parse(moniCoreCustomTheme) : undefined,
  },
  reducers: {
    updateActiveUser: (state, action) => {
      state.activeUser = action.payload;
    },
    updateIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    updateIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    updateActiveUserLoading: (state, action) => {
      state.activeUserLoading = action.payload;
    },
    updateTheme: (state, action) => {
      state.theme = action.payload;
    },
    updateCustomTheme: (state, action) => {
      state.customTheme = action.payload;
    },
    updateUserServers: (state, action) => {
      state.userServers = action.payload;
    },
    updateUserServersLoading: (state, action) => {
      state.userServersLoading = action.payload;
    },
  },
});

export const {
  updateActiveUser,
  updateIsAuthenticated,
  updateIsAdmin,
  updateActiveUserLoading,
  updateTheme,
  updateCustomTheme,
  updateUserServers,
  updateUserServersLoading,
} = session.actions;
export default session.reducer;