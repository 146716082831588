import { Field } from "formik";
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import React from "react";

export const InputField = ({ isSmall, value, required, label, name, setFieldValue, placeholder, touched, error }) => (
  <FormControl sx={{ flexBasis: isSmall ? 150 : 300, flexGrow: 1, minWidth: isSmall ? 150 : 200 }}>
    <Field
      as={TextField}
      value={value}
      name={name}
      label={label}
      onChange={(event) => setFieldValue(name, event.target.value)}
      InputProps={{
        placeholder
      }}
      InputLabelProps={{
        required
      }}
      error={touched && Boolean(error)}
      helperText={touched && error}
    />
  </FormControl>
)

export const SelectField = ({ value, required, label, name, setFieldValue, options, touched, error }) => (
  <FormControl sx={{ flexBasis: 150, flexGrow: 1, minWidth: 150 }}>
    <InputLabel required={required} id={name} error={touched && Boolean(error)}>{label}</InputLabel>
    <Select
      labelId={name}
      name={name}
      value={value}
      input={<OutlinedInput label={label} value={value} error={touched && Boolean(error)} />}
      onChange={(event) => setFieldValue(name, event.target.value)}
      error={touched && Boolean(error)}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
    {touched && error && (
      <Typography color="error" variant="caption">
        {error}
      </Typography>
    )}
  </FormControl>
)

