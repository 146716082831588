import { useDispatch, useSelector } from "react-redux";
import { updateIsSnackbarOpen, updateSnackbarConfig } from "../../store/actionReducer";
import { useEffect } from "react";

const useSnackbar = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.action.isSnackbarOpen);
  const config = useSelector((state) => state.action.snackbarConfig);

  const open = () => {
    dispatch(updateIsSnackbarOpen(true));
  }

  const setConfig = (value) => {
    dispatch(updateSnackbarConfig(value));
    if (value.severity) open();
  }

  const close = () => {
    dispatch(updateIsSnackbarOpen(false));
  }

  return {
    isOpen,
    config,
    open,
    close,
    setConfig,
  }
};

export default useSnackbar;
