import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, useTheme } from "@mui/material";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import useSnackbar from "../hooks/useSnackbar";
import useCommunications from "../hooks/useCommunications";


const validationSchema = Yup.object({
  topic: Yup.string()
    .max(128, 'Максимум 128 символів')
    .required("Тема обов'язкова"),
  message: Yup.string()
    .max(256, 'Максимум 256 символів')
    .required("Повідомлення обов'язкове"),
});


const SupportTicket = ({ open, handleClose }) => {
  const theme = useTheme();
  const { createTicket, ticketLoading } = useCommunications();
  const { setConfig } = useSnackbar();

  const handleSubmit = async (values, { resetForm, setErrors }) => {
    const response = await createTicket(values);

    if (!response?.id) {
      const [[, firstValue]] = Object.entries(response);
      if (typeof firstValue === 'string') {
        setErrors(response);
        setConfig({
          severity: 'error', text: firstValue,
        })
      } else {
        setConfig({
          severity: 'error', text: 'Помилка під час надсилання звернення',
        })
        resetForm({ values });
      }
    } else {
      setConfig({
        severity: 'success', text: 'Звернення успішно надіслано',
      })
      resetForm();
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      aria-labelledby="support-ticket-title"
    >
      <DialogTitle sx={{ color: theme.palette.text.primary, p: 2, textAlign: 'center' }} id="support-ticket-title">
        Звернення
      </DialogTitle>
      <Formik
        initialValues={{
          topic: '',
          message: '',
        }}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, handleChange, handleBlur, values, isSubmitting, submitForm, dirty, isValidating }) => (
          <>
            <DialogContent
              sx={{
                display: 'flex',
                width: '100%',
              }}
            >
              <Form
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: 10,
                }}
              >
                <Field
                  as={TextField}
                  name="topic"
                  label="Тема"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.topic}
                  error={touched.topic && Boolean(errors.topic)}
                  helperText={touched.topic && errors.topic}
                />

                <Field
                  as={TextField}
                  name="message"
                  label="Повідомлення"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  sx={{
                    mt: 1,
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                  error={touched.message && Boolean(errors.message)}
                  helperText={touched.message && errors.message}
                />
              </Form>
            </DialogContent>
            <DialogActions sx={{ p: 1, }}>
              <Button
                sx={{
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: 3,
                  textTransform: 'capitalize',
                  px: 2,
                  m: 1,
                }}
                onClick={handleClose}
              >Закрити</Button>
              <Button
                sx={{
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 3,
                  textTransform: 'capitalize',
                  px: 2,
                  m: 1,
                }}
                disabled={!dirty || isSubmitting || isValidating || ticketLoading}
                onClick={submitForm}
              >Надіслати</Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
}

export default SupportTicket;

