import { useDispatch, useSelector } from "react-redux";
import {
  getComments,
  createComment,
  createCommentLike,
  createServerLike,
  updateComment,
  removeComment, createSupportTicket, createReportTicket
} from "../../api/api";
import { updateLikeLoading, updateComments, updateCommentsTotal, updateCommentsLoading, updateActiveCommentsPage, updateTicketLoading } from "../../store/communicationsReducer";

const useCommunications = () => {
  const dispatch = useDispatch();
  const likeLoading = useSelector((state) => state.communications.likeLoading);
  const comments = useSelector((state) => state.communications.comments);
  const commentsTotal = useSelector((state) => state.communications.commentsTotal);
  const activePage = useSelector((state) => state.communications.activeCommentsPage);
  const commentsLoading = useSelector((state) => state.communications.commentsLoading);
  const ticketLoading = useSelector((state) => state.communications.ticketLoading);

  const changeLikeLoading = (value) => {
    dispatch(updateLikeLoading(value));
  }

  const changeComments = (value) => {
    dispatch(updateComments(value));
  }

  const changeCommentsTotal = (value) => {
    dispatch(updateCommentsTotal(value));
  }

  const changeCommentsLoading = (value) => {
    dispatch(updateCommentsLoading(value));
  }

  const changePage = (value) => {
    dispatch(updateActiveCommentsPage(value));
  }

  const changeTicketLoading = (value) => {
    dispatch(updateTicketLoading(value));
  }

  const likeServer = async (pk) => {
    changeLikeLoading(true);
    const result = await createServerLike(pk);
    changeLikeLoading(false);
    return result?.data;
  }

  const likeComment = async (pk) => {
    changeLikeLoading(true);
    const result = await createCommentLike(pk);
    if (result?.data?.id) {
      changeComments(comments.map(item => {
        if (item.id === pk) {
          return { ...item, likes: result?.data?.likes };
        }
        return item;
      }));
    }
    changeLikeLoading(false);
    return result?.data;
  }

  const addComment = async (pk, data) => {
    changeCommentsLoading(true);
    const result = await createComment(pk, data, activePage);
    if (result?.data?.id) {
      changeComments(result?.data?.comments);
      changeCommentsTotal(result?.data?.count);
    }
    changeCommentsLoading(false);
    return result?.data;
  }

  const editComment = async (pk, data) => {
    changeCommentsLoading(true);
    const result = await updateComment(pk, data, activePage);
    if (result?.data?.id) {
      changeComments(result?.data?.comments);
    }
    changeCommentsLoading(false);
    return result?.data;
  }

  const deleteComment = async (pk) => {
    changeCommentsLoading(true);
    const result = await removeComment(pk, activePage);
    if (result?.data?.id) {
      changeComments(result?.data?.comments);
    }
    changeCommentsLoading(false);
    console.log(result?.data)
    return result?.data;
  }

  const fetchComments = async (pk, page) => {
    changeCommentsLoading(true);
    const result = await getComments(pk, page);
    changeComments(result?.data?.data);
    changeCommentsTotal(result?.data?.count);
    changeCommentsLoading(false);
  }

  const createTicket = async (data) => {
    changeTicketLoading(true);
    const ticket = await createSupportTicket(data);
    changeTicketLoading(false);
    return ticket?.data;
  }

  const createReport = async (data) => {
    changeTicketLoading(true);
    const ticket = await createReportTicket(data);
    changeTicketLoading(false);
    return ticket?.data;
  }

  return {
    comments,
    commentsLoading,
    commentsTotal,
    likeLoading,
    activePage,
    ticketLoading,
    likeServer,
    likeComment,
    addComment,
    editComment,
    deleteComment,
    changePage,
    fetchComments,
    createTicket,
    createReport,
  }
}

export default useCommunications;
