import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import LogoIcon from "../../assets/icons/Logo";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material";
import HoverTooltip from "../tooltips/HoverTooltip";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullMenu = ({ open, handleClose, children }) => {
  const theme = useTheme();

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.background.default,
        }
      }}
    >
      <AppBar sx={{ position: 'relative', backgroundColor: theme.palette.background.paper, }}>
        <Toolbar
          sx={{
            minHeight: '56px !important',
            backgroundColor: theme.palette.background.paper,
            paddingRight: { xs: '6px !important', sm: '8px !important' },
            paddingLeft: { xs: '6px !important', sm: '8px !important' }
          }}
        >
          <Typography sx={{ display: 'flex', flex: 1, alignItems: 'center' }} variant="h6" component="div">
            <LogoIcon width={30} height={30} fill={theme.palette.text.secondary} />
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <HoverTooltip title="Закрити">
              <CloseIcon
                sx={{
                  fill: theme.palette.text.secondary, width: 30, height: 30
                }}
              />
            </HoverTooltip>
          </IconButton>
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
}

export default FullMenu;
