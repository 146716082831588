import { useDispatch, useSelector } from "react-redux";
import {
  updateServers,
  updateServersLoading,
  updateActiveServer,
  updateActiveServersPage,
  updateServersTotal,
  updateServerLoading,
  updateSelectedServer,
  updateSelectedServerStatistics,
  updateSelectedServerLoading,
  updateStatisticsTimeframe,
  updateSelectedServerError,
} from "../../store/navigationReducer";
import { createServer, editServer, getServer, getServers, getServerStatistics, getUserServers } from "../../api/api";
import { updateUserServers, updateUserServersLoading } from "../../store/sessionReducer";

const useServers = () => {
  const dispatch = useDispatch();
  const servers = useSelector((state) => state.navigation.servers);
  const serversLoading = useSelector((state) => state.navigation.serversLoading);
  const serverLoading = useSelector((state) => state.navigation.serverLoading);
  const userServers = useSelector((state) => state.session.userServers);
  const userServersLoading = useSelector((state) => state.session.userServersLoading);
  const activeServer = useSelector((state) => state.navigation.activeServer);
  const activePage = useSelector((state) => state.navigation.activeServersPage);
  const serversTotal = useSelector((state) => state.navigation.serversTotal);
  const selectedServer = useSelector((state) => state.navigation.selectedServer);
  const selectedServerError = useSelector((state) => state.navigation.selectedServerError);
  const selectedServerStatistics = useSelector((state) => state.navigation.selectedServerStatistics);
  const statisticsTimeframe = useSelector((state) => state.navigation.statisticsTimeframe);
  const selectedServerLoading = useSelector((state) => state.navigation.selectedServerLoading);

  const changeServers = (value) => {
    dispatch(updateServers(value));
  }

  const changeServersTotal = (value) => {
    dispatch(updateServersTotal(value));
  }

  const changeServersLoading = (value) => {
    dispatch(updateServersLoading(value));
  }

  const changeServerLoading = (value) => {
    dispatch(updateServerLoading(value));
  }

  const changeActiveServer = (value) => {
    dispatch(updateActiveServer(value));
  }

  const changeSelectedServer = (value) => {
    dispatch(updateSelectedServer(value));
  }

  const changeSelectedServerError = (value) => {
    dispatch(updateSelectedServerError(value));
  }

  const changeSelectedServerStatistics = (value) => {
    dispatch(updateSelectedServerStatistics(value));
  }

  const changeStatisticsTimeframe = (value) => {
    dispatch(updateStatisticsTimeframe(value));
  }

  const changeSelectedServerLoading = (value) => {
    dispatch(updateSelectedServerLoading(value));
  }

  const changePage = (value) => {
    dispatch(updateActiveServersPage(value));
    sessionStorage.setItem('moniCoreServersPage', value);
  }

  const changeUserServers = (value) => {
    dispatch(updateUserServers(value));
  }

  const changeUserServersLoading = (value) => {
    dispatch(updateUserServersLoading(value));
  }

  const fetch = async (category, tag, search, order, page) => {
    changeServersLoading(true);
    const servers = await getServers(category, tag, search, order, page);
    changeServers(servers?.data?.data);
    changeServersTotal(servers?.data?.count);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    changeServersLoading(false);
  }

  const fetchUserServers = async () => {
    changeUserServersLoading(true);
    const servers = await getUserServers();
    changeUserServers(servers?.data);
    changeUserServersLoading(false);
  }

  const create = async (data) => {
    changeServerLoading(true);
    const server = await createServer(data);
    changeServerLoading(false);
    return server;
  }

  const edit = async (data, id) => {
    changeServerLoading(true);
    const server = await editServer(data, id);
    changeServerLoading(false);
    return server;
  }

  const select = async (server) => {
    changeSelectedServerError(undefined);
    changeSelectedServerLoading(true);
    const result = await getServer(server);
    if (result?.data?.id) {
      changeSelectedServer(result.data);
      const statistics = await getServerStatistics(result?.data?.id, statisticsTimeframe);
      changeSelectedServerStatistics(statistics?.data);
    } else {
      changeSelectedServerError(result?.data);
    }
    changeSelectedServerLoading(false);
  }

  const getStatistics = async (timeframe) => {
    changeSelectedServerLoading(true);
    const statistics = await getServerStatistics(selectedServer?.id, timeframe);
    changeSelectedServerStatistics(statistics?.data);
    changeSelectedServerLoading(false);
  }

  return {
    fetch,
    create,
    edit,
    changeActiveServer,
    changePage,
    fetchUserServers,
    changeUserServers,
    changeSelectedServer,
    changeStatisticsTimeframe,
    changeSelectedServerError,
    getStatistics,
    select,
    servers,
    serversLoading,
    serverLoading,
    activeServer,
    activePage,
    serversTotal,
    userServers,
    userServersLoading,
    selectedServer,
    selectedServerStatistics,
    statisticsTimeframe,
    selectedServerLoading,
    selectedServerError,
  }

};

export default useServers;
