import { useEffect, useState } from "react";
import useServers from "./hooks/useServers";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import { Avatar, Box, Typography, useTheme } from "@mui/material";
import { getTransparentColor } from "../themes";
import ServerOnlineRating from "./ServerOnlineRating";
import ServerTags from "./ServerTags";
import DrawIcon from '@mui/icons-material/Draw';
import ServerIpVersion from "./ServerIpVersion";
import UserServersEmptyPlaceholder from "./placeholders/UserServersEmptyPlaceholder";
import BenefitsIndicators from "./BenefitsIndicators";
import { useNavigate } from "react-router-dom";

const UserServersList = ({ onEdit, handleClose }) => {
  const { userServers, fetchUserServers, userServersLoading } = useServers();
  const theme = useTheme();
  const navigate = useNavigate();
  const [focused, setFocused] = useState('');

  const onFocusServer = (slug) => {
    setFocused(slug)
  }

  useEffect(() => {
    if (!userServersLoading) {
      fetchUserServers()
    }
  }, []);

  const handleServerClick = (slug) => {
    navigate(`/server/${slug}`);
    handleClose()
  }

  return (
    <Container
      maxWidth="none"
      id="servers"
      sx={{
        p: '16px 8px !important',
        width: "100%",
        overflow: "hidden",
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <Grid
        container
        spacing={3}
        columns={12}
        sx={{
          width: '100%',
          maxWidth: '1920px',
        }}
      >
        {userServers?.length ? (
          userServers.map((s, i) => (
            <Grid
              key={`${s.slug}-${i}`}
              size={{ xs: 12, md: 6, xl: 4 }}
              onMouseEnter={() => onFocusServer(s.slug)}
              onMouseLeave={() => onFocusServer('')}
              sx={{
                borderRadius: 3,
                minHeight: 100,
                overflow: 'hidden',
                boxShadow: `0 6px 6px -4px ${focused === s.slug ? theme.palette.primary.main : getTransparentColor('#000000')}`,
              }}
            >
              <Box sx={{
                backgroundImage: `url(${s.banner})`,
                backgroundColor: theme.palette.background.paper,
                backgroundSize: 'cover',
                backgroundPosition: 'top left',
                backgroundRepeat: 'no-repeat',
                height: '100%',
                borderRadius: 3,
                position: 'relative',
                p: 2,
                pr: { xs: 2, sm: 6 },
                pb: { xs: 7, sm: 2 },
              }}>
                <BenefitsIndicators server={s} theme={theme} />
                <Box
                  sx={{
                    display: 'flex',
                    backgroundColor: getTransparentColor(theme.palette.text.secondary, focused === s.slug ? 0.7 : 0.3),
                    opacity: focused === s.slug ? 1 : 0.5,
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 0,
                    position: 'absolute',
                    top: { xs: 'auto', sm: 0 },
                    left: { xs: 0, sm: 'auto' },
                    right: 0,
                    bottom: 0,
                    zIndex: 3,
                    cursor: 'pointer',
                  }}
                  onClick={() => onEdit(s)}
                >
                  <DrawIcon
                    sx={{
                      fontSize: 40,
                      p: 0.5,
                      fill: focused === s.slug ? theme.palette.background.default : theme.palette.text.secondary,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    height: '100%',
                    zIndex: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <Avatar
                      src={s.logo || s.category.icon}
                      sx={{
                        mr: 2,
                        mb: 1,
                        borderRadius: 3,
                        width: 65,
                        height: 65,
                        cursor: 'pointer',
                        transition: 'transform 0.3s ease-in-out',
                        '&:hover': {
                          transform: 'scale(1.2) rotate(-5deg)',
                        }
                      }}
                      onClick={() => handleServerClick(s.slug)}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography
                        variant="h3"
                        component="h3"
                        sx={{
                          fontSize: { xs: 16, lg: 20 },
                          fontWeight: theme.typography.fontWeightBold,
                          color: theme.palette.text.primary,
                          mb: 0.3,
                        }}
                      >
                        {s.name}
                      </Typography>
                      <ServerOnlineRating server={s} />
                    </Box>
                  </Box>
                  <Typography
                    variant="p"
                    component="p"
                    sx={{
                      fontSize: 14,
                      fontWeight: theme.typography.fontWeightRegular,
                      color: theme.palette.text.primary,
                      mb: 1,
                    }}
                  >
                    {s.short_description}
                  </Typography>
                  <ServerTags tags={s.tags} disabled />
                  <ServerIpVersion server={s} />
                </Box>
              </Box>
            </Grid>
          ))
        ) : <UserServersEmptyPlaceholder loading={userServersLoading} />}
      </Grid>
    </Container>
  )

};

export default UserServersList;
