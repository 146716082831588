import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Typography, useTheme } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import useSession from "../hooks/useSession";
import { MuiColorInput } from 'mui-color-input'
import Color from "color";

const isValidColor = (colorString) => {
  try {
    Color(colorString);
    return true;
  } catch (error) {
    return false;
  }
};


const ThemeSettings = ({ open, handleClose }) => {
  const { customTheme: customThemeSetup, changeCustomTheme, changeTheme } = useSession();
  const theme = useTheme()
  const [currentTheme, setCurrentTheme] = useState(theme);
  const [currentThemeConfig, setCurrentThemeConfig] = useState(customThemeSetup || {});
  const [isTouched, setIsTouched] = useState(false);
  const [primaryColor, setPrimaryColor] = useState(currentTheme.palette.primary.main);
  const [secondaryColor, setSecondaryColor] = useState(currentTheme.palette.secondary.main);
  const [backgroundDefaultColor, setBackgroundDefaultColor] = useState(currentTheme.palette.background.default);
  const [backgroundPaperColor, setBackgroundPaperColor] = useState(currentTheme.palette.background.paper);
  const [textPrimaryColor, setTextPrimaryColor] = useState(currentTheme.palette.text.primary);
  const [textSecondaryColor, setTextSecondaryColor] = useState(currentTheme.palette.text.secondary);

  useEffect(() => {
    setCurrentTheme(theme);
    setPrimaryColor(theme.palette.primary.main);
    setSecondaryColor(theme.palette.secondary.main);
    setBackgroundDefaultColor(theme.palette.background.default);
    setBackgroundPaperColor(theme.palette.background.paper);
    setTextPrimaryColor(theme.palette.text.primary);
    setTextSecondaryColor(theme.palette.text.secondary);
  }, [theme]);

  const handlePrimaryColorChange = (newColor) => {
    setPrimaryColor(newColor);
    if (!isValidColor(newColor)) return null;
    const config = {
      ...currentTheme,
      palette: {
        ...currentTheme.palette,
        primary: {
          main: newColor,
        },
      },
    }
    setCurrentThemeConfig(config)
    const updatedTheme = createTheme(config);
    setCurrentTheme(updatedTheme);
    setIsTouched(true);
  };

  const handleSecondaryColorChange = (newColor) => {
    setSecondaryColor(newColor);
    if (!isValidColor(newColor)) return null;
    const config = {
      ...currentTheme,
      palette: {
        ...currentTheme.palette,
        secondary: {
          main: newColor,
        },
      },
    }
    setCurrentThemeConfig(config)
    const updatedTheme = createTheme(config);
    setCurrentTheme(updatedTheme);
    setIsTouched(true);
  };

  const handleBackgroundMainColorChange = (newColor) => {
    setBackgroundDefaultColor(newColor);
    if (!isValidColor(newColor)) return null;
    const config = {
      ...currentTheme,
      palette: {
        ...currentTheme.palette,
        background: {
          ...currentTheme.palette.background,
          default: newColor,
        },
      },
    }
    setCurrentThemeConfig(config)
    const updatedTheme = createTheme(config);
    setCurrentTheme(updatedTheme);
    setIsTouched(true);
  };

  const handleBackgroundPaperColorChange = (newColor) => {
    setBackgroundPaperColor(newColor);
    if (!isValidColor(newColor)) return null;
    const config = {
      ...currentTheme,
      palette: {
        ...currentTheme.palette,
        background: {
          ...currentTheme.palette.background,
          paper: newColor,
        },
      },
    }
    setCurrentThemeConfig(config)
    const updatedTheme = createTheme(config);
    setCurrentTheme(updatedTheme);
    setIsTouched(true);
  };

  const handleTextPrimaryColorChange = (newColor) => {
    setTextPrimaryColor(newColor);
    if (!isValidColor(newColor)) return null;
    const config = {
      ...currentTheme,
      palette: {
        ...currentTheme.palette,
        text: {
          ...currentTheme.palette.text,
          primary: newColor,
        },
      },
    }
    setCurrentThemeConfig(config)
    const updatedTheme = createTheme(config);
    setCurrentTheme(updatedTheme);
    setIsTouched(true);
  };

  const handleTextSecondaryColorChange = (newColor) => {
    setTextSecondaryColor(newColor);
    if (!isValidColor(newColor)) return null;
    const config = {
      ...currentTheme,
      palette: {
        ...currentTheme.palette,
        text: {
          ...currentTheme.palette.text,
          secondary: newColor,
        },
      },
    }
    setCurrentThemeConfig(config)
    const updatedTheme = createTheme(config);
    setCurrentTheme(updatedTheme);
    setIsTouched(true);
  };

  const handleSave = () => {
    changeTheme('custom');
    changeCustomTheme({
      palette: {
        mode: 'dark',
        primary: {
          main: currentThemeConfig?.palette?.primary?.main || currentTheme.palette.primary.main,
        },
        secondary: {
          main: currentThemeConfig?.palette?.secondary?.main || currentTheme.palette.secondary.main,
        },
        background: {
          default: currentThemeConfig?.palette?.background?.default || currentTheme.palette.background.default,
          paper: currentThemeConfig?.palette?.background?.paper || currentTheme.palette.background.paper,
        },
        text: {
          primary: currentThemeConfig?.palette?.text?.primary || currentTheme.palette.text.primary,
          secondary: currentThemeConfig?.palette?.text?.secondary || currentTheme.palette.text.secondary,
        },
      },
    });
    setIsTouched(false);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      aria-labelledby="theme-settings-title"
    >
      <DialogTitle sx={{ color: theme.palette.text.primary, p: 2, textAlign: 'center' }} id="theme-settings-title">
        Персональна тема
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-evenly',
          gap: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Typography
            variant="h3"
            component="h3"
            sx={{
              fontSize: 14,
              fontWeight: theme.typography.fontWeightBold,
              color: theme.palette.text.primary,
              cursor: 'default',
              mb: 1
            }}
          >
            Основний колір
          </Typography>
          <MuiColorInput isAlphaHidden value={primaryColor} format='hex' onChange={handlePrimaryColorChange} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Typography
            variant="h3"
            component="h3"
            sx={{
              fontSize: 14,
              fontWeight: theme.typography.fontWeightBold,
              color: theme.palette.text.primary,
              cursor: 'default',
              mb: 1
            }}
          >
            Другорядний колір
          </Typography>
          <MuiColorInput isAlphaHidden value={secondaryColor} format='hex' onChange={handleSecondaryColorChange} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Typography
            variant="h3"
            component="h3"
            sx={{
              fontSize: 14,
              fontWeight: theme.typography.fontWeightBold,
              color: theme.palette.text.primary,
              cursor: 'default',
              mb: 1
            }}
          >
            Основний колір фону
          </Typography>
          <MuiColorInput isAlphaHidden value={backgroundDefaultColor} format='hex' onChange={handleBackgroundMainColorChange} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Typography
            variant="h3"
            component="h3"
            sx={{
              fontSize: 14,
              fontWeight: theme.typography.fontWeightBold,
              color: theme.palette.text.primary,
              cursor: 'default',
              mb: 1
            }}
          >
            Другорядний колір фону
          </Typography>
          <MuiColorInput isAlphaHidden value={backgroundPaperColor} format='hex' onChange={handleBackgroundPaperColorChange} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Typography
            variant="h3"
            component="h3"
            sx={{
              fontSize: 14,
              fontWeight: theme.typography.fontWeightBold,
              color: theme.palette.text.primary,
              cursor: 'default',
              mb: 1
            }}
          >
            Колір тексту
          </Typography>
          <MuiColorInput isAlphaHidden value={textPrimaryColor} format='hex' onChange={handleTextPrimaryColorChange} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Typography
            variant="h3"
            component="h3"
            sx={{
              fontSize: 14,
              fontWeight: theme.typography.fontWeightBold,
              color: theme.palette.text.primary,
              cursor: 'default',
              mb: 1
            }}
          >
            Колір іконок
          </Typography>
          <MuiColorInput isAlphaHidden value={textSecondaryColor} format='hex' onChange={handleTextSecondaryColorChange} />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 1, }} >
        <Button
          sx={{
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.main,
            borderRadius: 3,
            textTransform: 'capitalize',
            px: 2,
            m: 1,
          }}
          onClick={handleClose}
        >Закрити</Button>
        <Button
          sx={{
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.main,
            borderRadius: 3,
            textTransform: 'capitalize',
            px: 2,
            m: 1,
          }}
          disabled={
            !isTouched
              || !isValidColor(primaryColor)
              || !isValidColor(secondaryColor)
              || !isValidColor(backgroundDefaultColor)
              || !isValidColor(backgroundPaperColor)
              || !isValidColor(textPrimaryColor)
              || !isValidColor(textSecondaryColor)
          }
          onClick={handleSave}
        >Зберегти</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ThemeSettings;
