import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AppRouter } from "./AppRouter";

const rootElement = createRoot(document.getElementById("root"));
rootElement.render(<AppRouter />);

// https://bit.ly/CRA-vitals
reportWebVitals();
