import * as React from "react";
import { getTransparentColor } from "../../themes";
import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import ClassIcon from "@mui/icons-material/Class";
import PersonIcon from "@mui/icons-material/Person";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CommentIcon from "@mui/icons-material/Comment";
import HoverTooltip from "../tooltips/HoverTooltip";
import DoneIcon from "@mui/icons-material/Done";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState } from "react";
import { formatNumber } from "../../utils";

const Information = ({ server }) => {
  const theme = useTheme();

  const [copiedData, setCopiedData] = useState({});
  const handleCopy = (key, value) => {
    navigator.clipboard.writeText(value)
      .then(() => {
        setCopiedData({ ...copiedData, [key]: true });
        setTimeout(() => setCopiedData({ ...copiedData, [key]: false }), 2000);
      })
      .catch(err => console.error("Не вдалося скопіювати!", err));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: { xs: '100%', md: 'calc(50% - 12px)', lg: '100%' },
        backgroundColor: theme.palette.background.paper,
        boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
        borderRadius: 5,
        p: 2,
        px: 4,
      }}
    >
      <Typography
        variant="h3"
        component="h3"
        sx={{
          fontSize: 20,
          mb: 2
        }}
      >
        Інформація
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <ClassIcon sx={{ fill: theme.palette.text.secondary, fontSize: 24 }} />
          <Typography
            variant="h3"
            component="h3"
            sx={{
              fontSize: 16,
              ml: 1
            }}
          >
            {server.category.name}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <PersonIcon sx={{ fill: theme.palette.text.secondary, fontSize: 24 }} />
          <Typography
            variant="h3"
            component="h3"
            sx={{
              fontSize: 16,
              ml: 1
            }}
          >
            {formatNumber(server.server_status.online || 0, 0)} / {formatNumber(server.server_status.max || 0, 0)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FavoriteIcon sx={{ fill: theme.palette.text.secondary, fontSize: 24 }} />
            <Typography
              variant="h3"
              component="h3"
              sx={{
                fontSize: 16,
                ml: 1
              }}
            >
              {formatNumber(server.likes, 0)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              ml: 2,
            }}
          >
            <CommentIcon sx={{ fill: theme.palette.text.secondary, fontSize: 24 }} />
            <Typography
              variant="h3"
              component="h3"
              sx={{
                fontSize: 16,
                ml: 1
              }}
            >
              {formatNumber(server.comments, 0)}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {!server.main_ip && server.discord_url ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 1,
              }}
            >
              <HoverTooltip placement="bottom" title="Копіювати">
                {copiedData?.discord_url ? (
                  <DoneIcon sx={{ fill: theme.palette.text.secondary, fontSize: 24 }} />
                ) : (
                  <ContentCopyIcon
                    sx={{ cursor: 'pointer', fill: theme.palette.text.secondary, fontSize: 24 }}
                    onClick={() => handleCopy('discord_url', server.discord_url)}
                  />
                )}
              </HoverTooltip>
              <HoverTooltip title="Запрошення">
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{
                    fontSize: 16,
                    ml: 1
                  }}
                >
                  {server.discord_url}
                </Typography>
              </HoverTooltip>
            </Box>
          ) : null}
          {server.main_ip ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 1,
              }}
            >
              <HoverTooltip title="Копіювати">
                {copiedData?.main_ip ? (
                  <DoneIcon sx={{ fill: theme.palette.text.secondary, fontSize: 24 }} />
                ) : (
                  <ContentCopyIcon
                    sx={{ cursor: 'pointer', fill: theme.palette.text.secondary, fontSize: 24 }}
                    onClick={() => handleCopy('main_ip', server.main_ip)}
                  />
                )}
              </HoverTooltip>
              <HoverTooltip title="IP">
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{
                    fontSize: 16,
                    ml: 1
                  }}
                >
                  {server.main_ip}
                </Typography>
              </HoverTooltip>
            </Box>
          ) : null}
          {server.additional_ip ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 1,
              }}
            >
              <HoverTooltip placement="bottom" title="Копіювати">
                {copiedData?.additional_ip ? (
                  <DoneIcon sx={{ fill: theme.palette.text.secondary, fontSize: 24 }} />
                ) : (
                  <ContentCopyIcon
                    sx={{ cursor: 'pointer', fill: theme.palette.text.secondary, fontSize: 24 }}
                    onClick={() => handleCopy('additional_ip', server.additional_ip)}
                  />
                )}
              </HoverTooltip>
              <HoverTooltip title={`${server.additional_ip_label} IP`}>
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{
                    fontSize: 16,
                    ml: 1
                  }}
                >
                  {server.additional_ip}
                </Typography>
              </HoverTooltip>
            </Box>
          ) : null}
          {server.map_url ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 1,
              }}
            >
              <HoverTooltip placement="bottom" title="Копіювати">
                {copiedData?.map_url ? (
                  <DoneIcon sx={{ fill: theme.palette.text.secondary, fontSize: 24 }} />
                ) : (
                  <ContentCopyIcon
                    sx={{ cursor: 'pointer', fill: theme.palette.text.secondary, fontSize: 24 }}
                    onClick={() => handleCopy('map_url', server.map_url)}
                  />
                )}
              </HoverTooltip>
              <HoverTooltip title="Мапа">
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{
                    fontSize: 16,
                    ml: 1
                  }}
                >
                  {server.map_url}
                </Typography>
              </HoverTooltip>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
};

export default Information;
