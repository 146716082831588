import Container from "@mui/material/Container";
import useServerCategories from "./hooks/useServerCategories";
import { getTransparentColor } from "../themes";
import ServerTags from "./ServerTags";
import { Box, IconButton, InputBase, Menu, MenuItem, useTheme } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TuneIcon from '@mui/icons-material/Tune';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

let searchTimeout;

const ALL_TAG = {
  slug: 'all',
  name: 'Всі'
}

const ServersFilter = () => {
  const { categories, activeCategory, activeOrder, changeActiveServerSearch, changeActiveServerOrder } = useServerCategories();
  const { category } = useParams();
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState('');
  const [tagsToFilter, setTagsToFilter] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOrderChange = (order) => {
    changeActiveServerOrder(order);
    handleClose();
  }

  const onSearchChange = (event) => {
    setSearchValue(event.target.value);
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      changeActiveServerSearch(event.target.value || '');
    }, 1000);
  }

  useEffect(() => {
    setSearchValue('');
    changeActiveServerSearch(undefined);
    changeActiveServerOrder(undefined);
  }, [category]);

  const onSearchClear = () => {
    setSearchValue('');
    changeActiveServerSearch('');
  }

  useEffect(() => {
    if (activeCategory) setTagsToFilter(
      activeCategory.tags.length ? [ALL_TAG, ...activeCategory.tags] : activeCategory.tags
    );
    else if (categories) {
      const tagMap = new Map();
      categories.forEach(category => {
        category.tags.forEach(tag => {
          if (tagMap.has(tag.slug)) {
            tagMap.get(tag.slug).server_count += tag.server_count;
          } else {
            tagMap.set(tag.slug, { ...tag });
          }
        });
      });
      const tagsArray = Array.from(tagMap.values());
      tagsArray.sort((a, b) => b.server_count - a.server_count);
      setTagsToFilter(tagsArray.length ? [ALL_TAG, ...tagsArray] : tagsArray);
    }
  }, [activeCategory, categories]);

  return (
    <Container
      maxWidth="none"
      id="filter"
      sx={{
        p: '1em 70px 0.5em 70px !important',
        width: "100%",
        overflow: "hidden",
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          borderRadius: 3,
          minHeight: 100,
          overflow: 'hidden',
          width: '100%',
          p: 3,
          py: 2,
          backgroundColor: theme.palette.background.paper,
          boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            border: `1px solid ${theme.palette.secondary.main}`,
            pl: 2,
            pr: 0.5,
            mb: tagsToFilter.length ? 2 : 0,
            borderRadius: 3,
            backgroundColor: getTransparentColor(theme.palette.background.default),
            boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
          }}
        >
          <InputBase
            placeholder="Фільтруй базу..."
            variant="outlined"
            value={searchValue}
            onChange={onSearchChange}
            inputProps={{ 'aria-label': 'servers search' }}
            sx={{
              width: '100%',
              boxShadow: 'none',
              backgroundColor: 'transparent'
            }}
          />
          <IconButton type="button" sx={{
              width: 44,
              height: 44
            }} aria-label="servers search">
            {searchValue ? <ClearIcon sx={{ fill: theme.palette.text.secondary }} onClick={() => onSearchClear()} /> : <SearchIcon sx={{ fill: theme.palette.text.secondary }} />}
          </IconButton>
          <IconButton
            onClick={handleClick}
            sx={{
              width: 44,
              height: 44
            }}
            aria-controls={open ? 'order-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <TuneIcon sx={{ fill: theme.palette.text.secondary }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="order-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            MenuListProps={{
              disablePadding: true,
            }}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              onClick={() => handleOrderChange('-rating')}
              selected={activeOrder === '-rating' || activeOrder === undefined}
              sx={{
                p: 1,
                pr: 2,
                color: activeOrder === '-rating' || activeOrder === undefined ? theme.palette.text.primary : getTransparentColor(theme.palette.text.primary, 0.4)
              }}
            >
              <KeyboardArrowDownIcon sx={{ fill: activeOrder === '-rating' || activeOrder === undefined ? theme.palette.text.primary : getTransparentColor(theme.palette.text.primary, 0.4) }} />
              Рейтинг
            </MenuItem>
            <MenuItem
              onClick={() => handleOrderChange('rating')}
              selected={activeOrder === 'rating'}
              sx={{
                p: 1,
                pr: 2,
                color: activeOrder === 'rating' ? theme.palette.text.primary : getTransparentColor(theme.palette.text.primary, 0.4)
              }}
            >
              <KeyboardArrowUpIcon sx={{ fill: activeOrder === 'rating' ? theme.palette.text.primary : getTransparentColor(theme.palette.text.primary, 0.4) }} />
              Рейтинг
            </MenuItem>
            <MenuItem
              onClick={() => handleOrderChange('-created_at')}
              selected={activeOrder === '-created_at'}
              sx={{
                p: 1,
                pr: 2,
                color: activeOrder === '-created_at' ? theme.palette.text.primary : getTransparentColor(theme.palette.text.primary, 0.4)
              }}
            >
              <KeyboardArrowDownIcon sx={{ fill: activeOrder === '-created_at' ? theme.palette.text.primary : getTransparentColor(theme.palette.text.primary, 0.4) }} />
              Нові
            </MenuItem>
            <MenuItem
              onClick={() => handleOrderChange('created_at')}
              selected={activeOrder === 'created_at'}
              sx={{
                p: 1,
                pr: 2,
                color: activeOrder === 'created_at' ? theme.palette.text.primary : getTransparentColor(theme.palette.text.primary, 0.4)
              }}
            >
              <KeyboardArrowUpIcon sx={{ fill: activeOrder === 'created_at' ? theme.palette.text.primary : getTransparentColor(theme.palette.text.primary, 0.4) }} />
              Старі
            </MenuItem>
            <MenuItem
              onClick={() => handleOrderChange('-online')}
              selected={activeOrder === '-online'}
              sx={{
                p: 1,
                pr: 2,
                color: activeOrder === '-online' ? theme.palette.text.primary : getTransparentColor(theme.palette.text.primary, 0.4)
              }}
            >
              <KeyboardArrowDownIcon sx={{ fill: activeOrder === '-online' ? theme.palette.text.primary : getTransparentColor(theme.palette.text.primary, 0.4) }} />
              Онлайн
            </MenuItem>
            <MenuItem
              onClick={() => handleOrderChange('online')}
              selected={activeOrder === 'online'}
              sx={{
                p: 1,
                pr: 2,
                color: activeOrder === 'online' ? theme.palette.text.primary : getTransparentColor(theme.palette.text.primary, 0.4)
              }}
            >
              <KeyboardArrowUpIcon sx={{ fill: activeOrder === 'online' ? theme.palette.text.primary : getTransparentColor(theme.palette.text.primary, 0.4) }} />
              Онлайн
            </MenuItem>
          </Menu>
        </Box>
        {tagsToFilter.length ? <ServerTags tags={tagsToFilter} /> : null}
      </Box>
    </Container>
  )
};

export default ServersFilter;
