import * as React from "react";
import { getTransparentColor } from "../../themes";
import { MenuItem, Select, Typography, useTheme } from "@mui/material";
import MembersChart from "../charts/MembersChart";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import useServers from "../hooks/useServers";

const Statistics = () => {
  const theme = useTheme();
  const {
    selectedServer,
    selectedServerLoading,
    selectedServerStatistics,
    statisticsTimeframe,
    getStatistics,
    changeStatisticsTimeframe
  } = useServers();
  const [timeframe, setTimeframe] = useState(statisticsTimeframe);

  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  }

  useEffect(() => {
    if (!selectedServerLoading && selectedServer) {
      changeStatisticsTimeframe(timeframe);
      getStatistics(timeframe);
    }
  }, [timeframe]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
        borderRadius: 5,
        p: 2,
        px: 4,
      }}
    >
      <Typography
        variant="h3"
        component="h3"
        sx={{
          fontSize: 20,
          mb: 2
        }}
      >
        Статистика
      </Typography>
      <Select
        sx={{
          width: 'fit-content',
          minWidth: 120
        }}
        disabled={selectedServerLoading}
        value={timeframe}
        onChange={handleTimeframeChange}
      >
        <MenuItem value="today">Сьогодні</MenuItem>
        <MenuItem value="week">Тиждень</MenuItem>
        <MenuItem value="month">Місяць</MenuItem>
        <MenuItem value="year">Рік</MenuItem>
        <MenuItem value="all_time">Весь час</MenuItem>
      </Select>
      <MembersChart data={selectedServerStatistics} isShowMax={selectedServer.category.is_show_max} />
    </Box>
  )
};

export default Statistics;
