import React from "react";

const LogoIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      version="1"
      viewBox="0 0 1083 1083"
      {...props}
    >
      <path
        d="M5010 10534c-457-25-640-40-955-79-1060-132-2161-427-3305-885-113-45-211-84-217-87-16-6-18-544-3-833 77-1462 399-2868 941-4101 809-1843 2094-3261 3787-4179 142-76 155-82 180-71 123 57 519 289 757 446 496 325 876 635 1311 1069 434 434 729 792 1058 1285 1026 1532 1633 3486 1736 5586 12 258 13 745 1 777-7 20-52 41-258 122-1391 551-2620 842-3953 936-144 11-956 21-1080 14zM2490 8999c508-46 1042-289 1574-715 444-356 942-902 1284-1407 56-83 68-96 77-82 27 47 226 321 313 433 314 402 706 802 1062 1083 361 286 741 494 1104 603 474 144 948 120 1384-71l105-46-7-129c-9-186-42-494-82-783-205-1454-661-2818-1297-3880-319-532-718-1038-1108-1403l-93-87 43 60c244 337 513 846 698 1320 399 1020 590 2126 570 3299-3 180-8 330-10 333-14 13-378-200-570-335-913-639-1629-1505-2030-2457-30-72-62-151-72-178-10-26-19-46-21-44-1 1-28 65-59 142-124 307-308 656-500 945-209 316-408 562-680 839-259 264-494 471-755 662-246 181-679 443-698 424-4-4-7-226-7-493 1-588 23-897 101-1402 173-1121 592-2239 1128-3003l76-108-82 73c-108 97-333 339-482 518-434 520-794 1108-1103 1805-349 784-608 1658-772 2605-65 373-125 878-138 1145l-6 131 83 38c303 138 639 196 970 165z"
        transform="matrix(.1 0 0 -.1 0 1083)"
      ></path>
    </svg>
  );
}

export default LogoIcon;
