import { createSlice } from '@reduxjs/toolkit';

const action = createSlice({
  name: 'action',
  initialState: {
    isSnackbarOpen: false,
    snackbarConfig: {}
  },
  reducers: {
    updateIsSnackbarOpen: (state, action) => {
      state.isSnackbarOpen = action.payload;
    },
    updateSnackbarConfig: (state, action) => {
      state.snackbarConfig = action.payload;
    },
  },
});

export const {
  updateIsSnackbarOpen,
  updateSnackbarConfig,
} = action.actions;
export default action.reducer;