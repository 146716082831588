import * as React from 'react';
import Button from '@mui/material/Button';
import useSession from "../hooks/useSession";
import { Menu } from "@mui/material";
import GoogleIcon from "../../assets/icons/Google";
import DiscordIcon from "../../assets/icons/Discord";
import SteamIcon from "../../assets/icons/Steam";
import HoverTooltip from "../tooltips/HoverTooltip";


const Login = ({ open, handleClose, anchorEl }) => {
  const { login } = useSession();

  const handleOnProviderClick = (provider) => {
    login(provider);
    handleClose();
  }

  return (
    <Menu
      anchorEl={anchorEl}
      id="order-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      MenuListProps={{
        disablePadding: true,
      }}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            p: 1,
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <HoverTooltip title='Google авторизація' placement="left">
        <Button
          onClick={() => handleOnProviderClick('google')}
          sx={{
            borderRadius: 3,
            backgroundColor: '#A50E0E',
            m: 0.5,
          }}
        >
          <GoogleIcon fill='#fff' />
        </Button>
      </HoverTooltip>
      <HoverTooltip title='Discord авторизація' placement="left">
        <Button
          onClick={() => handleOnProviderClick('discord')}
          sx={{
            borderRadius: 3,
            backgroundColor: '#5865F2',
            m: 0.5,
          }}
        >
          <DiscordIcon fill='#fff' />
        </Button>
      </HoverTooltip>
      <HoverTooltip title='Steam авторизація' placement="left">
        <Button
          onClick={() => handleOnProviderClick('steam')}
          sx={{
            borderRadius: 3,
            backgroundColor: '#000000',
            m: 0.5,
          }}
        >
          <SteamIcon fill='#fff' />
        </Button>
      </HoverTooltip>
    </Menu>
  );
}

export default Login;