import { combineReducers } from 'redux';
import sessionReducer from './sessionReducer';
import communicationsReducer from './communicationsReducer';
import navigationReducer from "./navigationReducer";
import actionReducer from "./actionReducer";

const rootReducer = combineReducers({
  communications: communicationsReducer,
  session: sessionReducer,
  navigation: navigationReducer,
  action: actionReducer,
});

export default rootReducer;
