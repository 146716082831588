import { Snackbar } from "@mui/material";
import { Alert } from "@mui/lab";
import useSnackbar from "../hooks/useSnackbar";

const SnackbarProvider = () => {
  const { isOpen, close, config } = useSnackbar();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    close();
  };

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={config.severity}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {config.text}
      </Alert>
    </Snackbar>
  )
};

export default SnackbarProvider;
