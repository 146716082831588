import { createSlice } from '@reduxjs/toolkit';

const communications = createSlice({
  name: 'communications',
  initialState: {
    likeLoading: false,
    comments: undefined,
    commentsTotal: 0,
    activeCommentsPage: 1,
    commentsLoading: false,
    ticketLoading: false,
  },
  reducers: {
    updateLikeLoading: (state, action) => {
      state.likeLoading = action.payload;
    },
    updateComments: (state, action) => {
      state.comments = action.payload;
    },
    updateCommentsTotal: (state, action) => {
      state.commentsTotal = action.payload;
    },
    updateCommentsLoading: (state, action) => {
      state.commentsLoading = action.payload;
    },
    updateActiveCommentsPage: (state, action) => {
      state.activeCommentsPage = action.payload;
    },
    updateTicketLoading: (state, action) => {
      state.ticketLoading = action.payload;
    },
  },
});

export const {
  updateLikeLoading,
  updateComments,
  updateCommentsTotal,
  updateCommentsLoading,
  updateActiveCommentsPage,
  updateTicketLoading,
} = communications.actions;
export default communications.reducer;