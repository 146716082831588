import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import { Rating, Typography, useTheme } from "@mui/material";
import CommentForm from "../forms/CommentForm";
import { getTransparentColor } from "../../themes";
import useCommunications from "../hooks/useCommunications";
import useSession from "../hooks/useSession";
import HoverTooltip from "../tooltips/HoverTooltip";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { formatNumber, getTotalPages } from "../../utils";
import moment from "moment/moment";
import 'moment/locale/uk';
import useSnackbar from "../hooks/useSnackbar";
import { Pagination } from "@mui/lab";
import useServers from "../hooks/useServers";
import ReportDialog from "../modals/ReportDialog";
import FlagIcon from "@mui/icons-material/Flag";
import ReplyIcon from '@mui/icons-material/Reply';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplyCommentForm from "../forms/ReplyCommentForm";
import _ from "lodash";
import ConfirmationDialog from "../modals/ConfirmationDialog";

const Comments = () => {
  moment.locale('uk');
  const theme = useTheme();
  const { comments, commentsTotal, ticketLoading, commentsLoading, likeComment, activePage, changePage, fetchComments, deleteComment } = useCommunications();
  const { activeUser } = useSession();
  const { setConfig } = useSnackbar();
  const { selectedServer, changeSelectedServer } = useServers();
  const totalPages = getTotalPages(commentsTotal, 30);
  const [isCommentFormOpen, setIsCommentFormOpen] = useState(false);
  const [isResetForm, setIsResetForm] = useState(false);
  const [hasComment, setHasComment] = useState(false);
  const [userComment, setUserComment] = useState(undefined);
  const [selectedComment, setSelectedComment] = useState(undefined);
  const [reportOpen, setReportOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [commentToDeleteId, setCommentToDeleteId] = useState(undefined);
  const [replyComment, setReplyComment] = useState(undefined);
  const [page, setPage] = useState(activePage ? parseInt(activePage) : 1);

  const onPageChange = async (e, page) => {
    setPage(page);
    changePage(page);
    await fetchComments(selectedServer.id, page);
  }

  const handleCommentFormOpen = () => {
    setIsCommentFormOpen(true);
  }

  const handleCommentFormClose = () => {
    setIsCommentFormOpen(false);
  }

  const handleReportComment = (id) => {
    setSelectedComment(id);
    setReportOpen(true);
  }

  const handleReplyComment = (id) => {
    setReplyComment(id);
  }

  const handleLikeComment = async (commentId) => {
    const response = await likeComment(commentId);
    if (!response?.id) {
      const [[, firstValue]] = Object.entries(response);
      if (typeof firstValue === 'string') {
        setConfig({
          severity: 'error', text: firstValue,
        })
      } else if (Array.isArray(firstValue)) {
        setConfig({
          severity: 'error', text: _.head(firstValue),
        })
      }
    }
  }

  const handleDelete = async () => {
    try {
      const response = await deleteComment(commentToDeleteId)
      if (!response?.id) {
        const [[, firstValue]] = Object.entries(response);
        if (typeof firstValue === 'string') {
          setConfig({
            severity: 'error', text: firstValue,
          })
        } else if (Array.isArray(firstValue)) {
          setConfig({
            severity: 'error', text: _.head(firstValue),
          })
        } else {
          setConfig({
            severity: 'error', text: 'Помилка при видаленні відгуку',
          })
        }
      } else {
        setConfig({
          severity: 'success',
          text: 'Відгук успішно видалено',
        });
        if (response?.count) {
          changeSelectedServer({ ...selectedServer, comments: response?.count })
        }
      }
    } catch (error) {
      setConfig({
        severity: 'error',
        text: 'Щось пішло не так',
      });
    } finally {
      setIsDeleteConfirmationOpen(false);
      setCommentToDeleteId(undefined);
    }
  };

  useEffect(() => {
    if (activePage) {
      setPage(parseInt(activePage) || 1);
    }
  }, [activePage]);

  useEffect(() => {
    handleCommentFormClose();
    setUserComment(undefined)
    setHasComment(false);
  }, [isResetForm]);

  useEffect(() => {
    const userComment = (comments || []).find(c => c.user === activeUser?.id && !c.parent);
    setHasComment(Boolean(userComment));
    setIsCommentFormOpen(Boolean(userComment));
    setUserComment(userComment);
  }, [selectedServer, comments, activeUser])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
        borderRadius: 5,
        p: 2,
        px: 4,
      }}
    >
      <Typography
        variant="h3"
        component="h3"
        sx={{
          fontSize: 20,
          mb: 2
        }}
      >
        Відгуки
      </Typography>
      <Box
        sx={{
          borderBottom: comments?.length ? `1px solid ${theme.palette.divider}` : 'none',
          pb: comments?.length && !isCommentFormOpen ? 2 : 0,
          mb: comments?.length ? 2 : 0,
        }}
      >
        {isCommentFormOpen ? (
          <>
            <Button
              sx={{
                backgroundColor: theme.palette.primary.main,
                borderRadius: 3,
                pr: 2,
                width: 'fit-content',
              }}
              onClick={handleCommentFormClose}
            >
              <CloseIcon
                sx={{
                  fill: theme.palette.text.primary,
                  mr: 1,
                  ml: 0.5,
                }}
              />
              Закрити
            </Button>
            <CommentForm handleReset={() => setIsResetForm(!isResetForm)} comment={userComment} isEdit={hasComment} />
          </>
        ) : (
          <Button
            sx={{
              backgroundColor: theme.palette.primary.main,
              borderRadius: 3,
              pr: 2,
              width: 'fit-content',
            }}
            onClick={handleCommentFormOpen}
          >
            {hasComment ? (
              <EditIcon
                fontSize='small'
                sx={{
                  fill: theme.palette.text.primary,
                  mr: 1,
                  ml: 0.5,
                }}
              />
            ) : (
              <AddIcon
                sx={{
                  fill: theme.palette.text.primary,
                  mr: 1,
                  ml: 0.5,
                }}
              />
            )}
            {hasComment ? 'Редагувати' : 'Додати'}
          </Button>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {(comments || []).map(c => (
          <Box
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
              p: 1,
              pb: 3,
              pl: c.parent ? 3 : 1,
              borderLeft: c.parent ? `2px solid ${theme.palette.divider}` : 'none',
              borderBottomLeftRadius: c.parent ? 30 : 0,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                flexWrap: 'wrap',
                gap: 1,
              }}
            >
              <Typography
                variant="h4"
                component="h4"
                sx={{
                  fontSize: { xs: 18, md: 20 },
                }}
              >
                {c.author}
              </Typography>
              {!c.parent && <Rating
                name="rating"
                value={c.rating}
                readOnly
                precision={1}
                max={5}
                sx={{
                  fill: theme.palette.text.secondary
                }}
              />}
              <Typography
                variant="h5"
                component="p"
                sx={{
                  fontSize: { xs: 12, md: 14 },
                }}
              >
                {moment(c.created_at).format('DD.MM.YY HH:mm')}
              </Typography>
            </Box>
            <Box
              sx={{
                my: 1,
              }}
            >
              <Typography
                variant="h5"
                component="p"
                sx={{
                  fontSize: 16,
                }}
              >
                {c.text}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
              }}
            >
              {!c.parent && <Button
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  py: 1,
                  px: 2,
                  borderRadius: 3,
                  minWidth: 0,
                  fontSize: { xs: 14, md: 16 },
                  boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
                }}
                onClick={() => handleLikeComment(c.id)}
              >
                <HoverTooltip title="Вподобайка">
                  <FavoriteIcon fill={theme.palette.text.primary} sx={{ fontSize: 22 }} />
                  <Typography
                    variant="h4"
                    component="h4"
                    sx={{
                      fontSize: 16,
                      ml: 1
                    }}
                  >
                    {formatNumber(c.likes, 0)}
                  </Typography>
                </HoverTooltip>
              </Button>}
              <Button
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  py: 1,
                  px: 2,
                  borderRadius: 3,
                  fontSize: { xs: 14, md: 16 },
                  boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
                }}
                disabled={commentsLoading || activeUser?.id === c.user}
                onClick={() => handleReplyComment(c.id)}
              >
                <HoverTooltip title="Відповісти">
                  <ReplyIcon fill={theme.palette.text.primary} sx={{ fontSize: 24 }} />
                </HoverTooltip>
              </Button>
              <Button
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  py: 1,
                  px: 2,
                  borderRadius: 3,
                  fontSize: { xs: 14, md: 16 },
                  boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
                }}
                disabled={ticketLoading || activeUser?.id === c.user}
                onClick={() => handleReportComment(c.id)}
              >
                <HoverTooltip title="Скарга">
                  <FlagIcon fill={theme.palette.text.primary} sx={{ fontSize: 22 }} />
                </HoverTooltip>
              </Button>
              {c.parent && activeUser?.id === c.user && <Button
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  py: 1,
                  px: 2,
                  borderRadius: 3,
                  fontSize: { xs: 14, md: 16 },
                  boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
                }}
                disabled={ticketLoading}
                onClick={() => {
                  setCommentToDeleteId(c.id);
                  setIsDeleteConfirmationOpen(true);
                }}
              >
                <HoverTooltip title="Видалити">
                  <DeleteIcon fill={theme.palette.text.primary} sx={{ fontSize: 22 }} />
                </HoverTooltip>
              </Button>}
            </Box>
            {replyComment === c.id && <Box>
              <ReplyCommentForm parent={c.id} handleReset={() => setReplyComment(undefined)} />
            </Box>}
          </Box>
        ))}
        {totalPages && totalPages > 1 ? (
          <Pagination
            page={page}
            count={totalPages}
            onChange={onPageChange}
            size='large'
            sx={{
              mt: 3,
            }}
          />
        ) : null}
      </Box>
      <ReportDialog
        open={reportOpen}
        handleClose={() => setReportOpen(false)}
        instanceId={selectedComment}
        instanceType="comment"
      />
      <ConfirmationDialog
        open={isDeleteConfirmationOpen}
        handleClose={() => setIsDeleteConfirmationOpen(false)}
        handleDelete={handleDelete}
        question={"Бажаєте видалити відгук?"}
      />
    </Box>
  )
};

export default Comments;
