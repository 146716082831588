import { Box, Chip, Typography, useTheme } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import FavoriteIcon from '@mui/icons-material/Favorite';
import CommentIcon from '@mui/icons-material/Comment';
import { getContrastColor } from "../themes";
import HoverTooltip from "./tooltips/HoverTooltip";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PageviewIcon from '@mui/icons-material/Pageview';
import LockIcon from '@mui/icons-material/Lock';
import { useMemo } from "react";
import { formatNumber } from "../utils";
import ModerationChoice from "./server/ModerationChoice";

const ServerOnlineRating = ({ server, category }) => {
  const theme = useTheme();

  const status = useMemo(() => {
    switch (server.status) {
      case 1:
        return (
          <HoverTooltip title='Перевірка' placement="top">
            <Box
              sx={{
                display: 'flex',
                height: 'auto',
                width: 'fit-content'
              }}
            >
              <PageviewIcon fill={theme.palette.text.primary} />
            </Box>
          </HoverTooltip>
        )
      case 3:
        return (
          <HoverTooltip title='Приховано' placement="top">
            <Box
              sx={{
                display: 'flex',
                height: 'auto',
                width: 'fit-content'
              }}
            >
              <VisibilityOffIcon fill={theme.palette.text.primary} />
            </Box>
          </HoverTooltip>
        )
      case 4:
        return (
          <HoverTooltip title='Заблоковано' placement="top">
            <Box
              sx={{
                display: 'flex',
                height: 'auto',
                width: 'fit-content'
              }}
            >
              <LockIcon fill={theme.palette.text.primary} />
            </Box>
          </HoverTooltip>
        )
      default:
        return (
          <HoverTooltip title='Рейтинг' placement="top">
            <Box
              sx={{
                display: 'flex',
                height: 'auto',
                alignItems: 'center',
                width: 'fit-content'
              }}
            >
              <EmojiEventsIcon fontSize="small" fill={theme.palette.text.primary} />
              <Typography
                variant="h2"
                component="h2"
                sx={{
                  fontSize: 14,
                  ml: 0.5,
                  fontWeight: theme.typography.fontWeightBold,
                  color: theme.palette.text.primary,
                  cursor: 'default'
                }}
              >
                {formatNumber(server.rating)}
              </Typography>
            </Box>
          </HoverTooltip>
        )
    }
  }, [server, theme])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
        my: 0.5,
        flexWrap: 'wrap',
      }}
    >
      <ModerationChoice server={server} />
      <Box
        sx={{
          mr: 0.5,
          display: 'flex',
          width: 'fit-content',
        }}
      >
        {status}
      </Box>
      <HoverTooltip title="Онлайн" placement='top'>
        <Chip
          icon={<PersonIcon sx={{ fill: theme.palette.text.secondary, fontSize: 14 }} />}
          label={formatNumber(server.server_status.online || 0, 0)}
          sx={{
            borderRadius: 3,
            p: 0.5,
            height: 'auto',
            color: getContrastColor(theme.palette.secondary.main),
            backgroundColor: theme.palette.secondary.main
          }}
        />
      </HoverTooltip>
      <HoverTooltip title='Вподобайки' placement='top'>
        <Chip
          icon={<FavoriteIcon sx={{ fill: theme.palette.text.secondary, fontSize: 14 }} />}
          label={formatNumber(server.likes || 0, 0)}
          sx={{
            borderRadius: 3,
            p: 0.5,
            height: 'auto',
            color: getContrastColor(theme.palette.secondary.main),
            backgroundColor: theme.palette.secondary.main
          }}
        />
      </HoverTooltip>
      <HoverTooltip title='Відгуки' placement='top'>
        <Chip
          icon={<CommentIcon sx={{ fill: theme.palette.text.secondary, fontSize: 14 }} />}
          label={formatNumber(server.comments || 0, 0)}
          sx={{
            borderRadius: 3,
            p: 0.5,
            height: 'auto',
            color: getContrastColor(theme.palette.secondary.main),
            backgroundColor: theme.palette.secondary.main
          }}
        />
      </HoverTooltip>
      {category ? null : (
        <Chip
          label={server.category.name}
          sx={{
            borderRadius: 3,
            p: 0.5,
            height: 'auto',
            color: getContrastColor(theme.palette.secondary.main),
            backgroundColor: theme.palette.secondary.main
          }}
        />
      )}
    </Box>
  )
}

export default ServerOnlineRating;
