import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DoNotDisturb, Home } from "@mui/icons-material";
import { useTheme } from "@mui/material";

export default function ErrorPage() {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleGoHome = () => {
    navigate("/");
  }

  return (
    <Container
      maxWidth="none"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 !important",
        width: "100vw",
        height: "100vh"
      }}
    >
      <Box
        sx={{
          m: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <DoNotDisturb
          fontSize="large"
          sx={{
            fill: theme.palette.text.primary,
            width: { xs: 100, md: 150 },
            height: { xs: 100, md: 150 }
          }}
        />
        <Typography
          variant="h1"
          component="h1"
          sx={{
            fontSize: { xs: 24, md: 36 },
            fontWeight: 700,
            color: theme.palette.text.primary,
            my: 2,
            textAlign: "center"
          }}
        >
          Технічні шоколадки
        </Typography>
        <Button
          onClick={handleGoHome}
          sx={{
            display: 'flex',
            alignItems: 'center',
            py: 1,
            px: 2,
            borderRadius: 3,
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.main
          }}
        >
          <Home sx={{ fill: theme.palette.text.primary }} />
          <Typography
            variant="h1"
            component="h1"
            sx={{
              fontSize: { xs: 14, md: 18 },
              fontWeight: theme.typography.fontWeightMedium,
              color: theme.palette.text.primary,
              ml: 1
            }}
          >
            На головну
          </Typography>
        </Button>
      </Box>
    </Container>
  );
}