import React, { useRef } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  Button,
  Box,
  useTheme,
} from '@mui/material';
import _ from "lodash";
import useSnackbar from "../hooks/useSnackbar";
import useCommunications from "../hooks/useCommunications";
import useServers from "../hooks/useServers";

const validationSchema = Yup.object({
  text: Yup.string()
    .required("Текст обов'язковий")
    .min(4, 'Мінімум 4 символи')
    .max(256, 'Максимум 256 символів'),
});

const initialValues = {
  text: '',
  parent: null,
};

const ReplyCommentForm = ({ parent, handleReset }) => {
  const theme = useTheme();
  const { setConfig } = useSnackbar();
  const { selectedServer } = useServers();
  const { commentsLoading, addComment } = useCommunications();
  const formikRef = useRef(null);

  const handleSubmit = async (values, { resetForm, setErrors }) => {
    try {
      const data = {
        text: values.text,
        parent: parent,
      };
      const response = await addComment(selectedServer.id, data)
      if (!response?.id) {
        const [[, firstValue]] = Object.entries(response);
        if (typeof firstValue === 'string') {
          setErrors(response);
          setConfig({
            severity: 'error', text: firstValue,
          })
        } else if (Array.isArray(firstValue)) {
          setErrors(response);
          setConfig({
            severity: 'error', text: _.head(firstValue),
          })
        } else {
          setConfig({
            severity: 'error', text: 'Помилка при додаванні відповіді',
          })
          resetForm({ values });
        }
      } else {
        setConfig({
          severity: 'success',
          text: 'Відповідь успішно додано',
        });
        resetForm({ values });
        handleReset()
      }
    } catch (error) {
      setConfig({
        severity: 'error',
        text: 'Щось пішло не так',
      });
    }
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isSubmitting,
          dirty,
          isValidating,
        }) => (
          <Form>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.background.paper,
                py: 2,
                borderRadius: 2,
                gap: 2,
              }}
            >
              <TextField
                name="text"
                label="Текст"
                multiline
                rows={4}
                value={values.text}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                error={touched.text && Boolean(errors.text)}
                helperText={touched.text && errors.text}
              />

              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  mt: 1,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={commentsLoading || !dirty || isSubmitting || isValidating}
                  sx={{
                    height: 'fit-content',
                    width: 'fit-content',
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.text.primary,
                    borderRadius: 3,
                  }}
                >
                  Надіслати
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    height: 'fit-content',
                    width: 'fit-content',
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.text.primary,
                    borderRadius: 3,
                  }}
                  onClick={handleReset}
                >
                  Закрити
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ReplyCommentForm;
