import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import DnsIcon from '@mui/icons-material/Dns';

const UserServersEmptyPlaceholder = ({ loading }) => {
  const theme = useTheme();

  if (loading) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        mt: 3,
      }}
    >
      <DnsIcon
        sx={{
          fill: theme.palette.text.secondary,
          width: { xs: 50, sm: 70 },
          height: { xs: 50, sm: 70 }
        }}
      />
      <Typography
        variant="h1"
        component="h1"
        sx={{
          fontSize: { xs: 18, sm: 22 },
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette.text.secondary,
          my: 2,
          textAlign: "center"
        }}
      >
        Ви не маєте серверів
      </Typography>
    </Box>
  )
};

export default UserServersEmptyPlaceholder;
