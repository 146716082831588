import { useEffect, useState } from 'react';
import useServerCategories from "./hooks/useServerCategories";
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, useTheme } from "@mui/material";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import useServers from "./hooks/useServers";
import LogoIcon from "../assets/icons/Logo";
import ThemeNavigation from "./navigation/ThemeNavigation";
import UserNavigation from "./navigation/UserNavigation";
import HoverTooltip from "./tooltips/HoverTooltip";
import SupportNavigation from "./navigation/SupportNavigation";
import useSession from "./hooks/useSession";

const NavigationLayout = () => {
  const { isAuthenticated } = useSession();
  const {
    fetch: fetchCategories,
    categories,
    activeCategory,
    activeSearch,
    activeOrder,
    changeActiveServerCategory,
    changeActiveServerTag
  } = useServerCategories();
  const { fetch: fetchServers, serversLoading, activePage, changePage } = useServers();
  const navigate = useNavigate();
  const { category, tag } = useParams();
  const theme = useTheme();
  const [themePickerOpen, setThemePickerOpen] = useState(false);

  useEffect(() => {
    if (!categories) {
      fetchCategories();
      fetchServers(category, tag, activeSearch, activeOrder, activePage);
      changeActiveServerTag(tag);
    }
  }, []);

  useEffect(() => {
    if (categories && !serversLoading) {
      const currentCategory = (categories || []).find((c) => c.slug === category);
      changeActiveServerCategory(currentCategory);
      changeActiveServerTag(undefined);
      fetchServers(category, tag, undefined, undefined, 1);
      changePage(1);
    }
  }, [category]);

  useEffect(() => {
    if (!serversLoading) {
      fetchServers(category, tag, activeSearch, activeOrder, activePage);
    }
  }, [activePage]);

  useEffect(() => {
    if (categories && category) {
      const currentCategory = (categories || []).find((c) => c.slug === category);
      changeActiveServerCategory(currentCategory);
    }
  }, [categories]);

  useEffect(() => {
    if (!serversLoading) {
      changeActiveServerCategory(activeCategory);
      changeActiveServerTag(tag);
      fetchServers(category, tag, activeSearch, activeOrder, 1);
      changePage(1);
    }
  }, [tag]);

  useEffect(() => {
    if (!serversLoading && activeSearch !== undefined) {
      fetchServers(category, tag, activeSearch, activeOrder, 1);
      changePage(1);
    }
  }, [activeSearch]);

  useEffect(() => {
    if (!serversLoading && activeOrder !== undefined) {
      fetchServers(category, tag, activeSearch, activeOrder, 1);
      changePage(1);
    }
  }, [activeOrder]);

  const onCategoryClick = (category) => {
    if (category) navigate(`/${category}`);
    else {
      changePage(1);
      navigate('/');
    }
  }

  return <>
    <Drawer
      sx={{
        padding: 0,
        width: 35,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
      }}
      variant="permanent"
    >
      <List disablePadding sx={{ pt: 0.5 }}>
        <ListItem key='index' disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 30,
              p: 1,
              justifyContent: 'center'
            }}
            onClick={() => onCategoryClick()}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: 'center',
                m: 'auto',
              }}
            >
              <LogoIcon width={30} height={30} fill={theme.palette.text.secondary} />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <Divider sx={{ borderBottom: `2px solid ${theme.palette.divider}`, my: 0.5 }} />
        <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 60px)' }}>
          {(categories || []).map((c) => (
            <ListItem key={c.slug} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 30,
                  p: 1,
                  justifyContent: 'center'
                }}
                onClick={() => onCategoryClick(c.slug)}
              >
                <HoverTooltip title={c.name} placement='right'>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: 'center',
                      m: 'auto',
                    }}
                  >
                    <img src={c.icon} alt="Icon" style={{ width: 30, height: 30, objectFit: 'contain', color: theme.palette.text.secondary }} />
                  </ListItemIcon>
                </HoverTooltip>
              </ListItemButton>
            </ListItem>
          ))}
        </Box>
      </List>
    </Drawer>
    <Drawer
      sx={{
        padding: 0,
        width: 35,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
      }}
      variant="permanent"
      anchor="right"
    >
      <List disablePadding sx={{ pt: 0.5, height: '100%' }}>
        <ListItem key='index' disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 30,
              p: 0,
              justifyContent: 'center'
            }}
          >
            <UserNavigation />
          </ListItemButton>
        </ListItem>
        <Divider sx={{ borderBottom: `2px solid ${theme.palette.divider}`, my: 0.5 }} />
        <ListItem key='theme' disablePadding sx={{ display: 'block' }} onClick={() => setThemePickerOpen(true)}>
          <ThemeNavigation open={themePickerOpen} handleClose={() => setThemePickerOpen(false)} />
        </ListItem>
        {isAuthenticated ? (
          <ListItem
            key='support'
            disablePadding
            sx={{
              position: 'absolute',
              top: 'auto',
              bottom: 0,
              minHeight: 50,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 30,
                p: 0,
                justifyContent: 'center'
              }}
            >
              <SupportNavigation />
            </ListItemButton>
          </ListItem>
        ) : null}
      </List>
    </Drawer>
    <Outlet />
  </>;
};

export default NavigationLayout;