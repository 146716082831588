import { Box, Button, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

const ServerTags = ({ tags, disabled = false }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { category } = useParams();

  const onTagClick = (tag) => {
    if (disabled) return;

    if (tag === 'all') {
      if (category) navigate(`/${category}`);
      else navigate('/');
    }
    else if (category && tag) navigate(`/${category}/tag/${tag}`);
    else if (tag) navigate(`/tag/${tag}`);
    else if (category) navigate(`/${category}`);
    else navigate('/');
  }

  return (
    <Box
      sx={{
        mt: 'auto',
        display: 'flex',
        justifyContent: { xs: 'center', sm: 'flex-start' },
        flexWrap: 'wrap',
        gap: 1,
      }}
    >
      {tags.slice(0, 30).map((t) => (
        <Button
          key={t.slug}
          onClick={() => onTagClick(t.slug)}
          sx={{
            minWidth: 0,
            fontSize: 13,
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.default,
            border: `1px solid ${theme.palette.primary.main}`,
            textTransform: 'capitalize',
            cursor: disabled ? 'default' : 'pointer',
            borderRadius: 1,
            p: 0,
            pr: 0.7,
          }}
        >
          <Box
            sx={{
              width: 5,
              borderTopLeftRadius: 3,
              borderBottomLeftRadius: 3,
              backgroundColor: theme.palette.primary.main,
              height: '100%',
              mr: 0.7,
            }}
          />
          {t.name}
        </Button>
      ))}
    </Box>
  )
};

export default ServerTags;
