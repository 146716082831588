import React, { useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  MenuItem,
  Checkbox,
  Button,
  Select,
  InputLabel,
  FormControl, useTheme, OutlinedInput, Chip, Autocomplete, Avatar
} from '@mui/material';
import useServerCategories from "../hooks/useServerCategories";
import Box from "@mui/material/Box";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import useSession from "../hooks/useSession";
import useSnackbar from "../hooks/useSnackbar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Array from "lodash";
import _ from "lodash";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const validationSchema = Yup.object({
  name: Yup.string().required("Нікнейм обов'язковий").min(4, 'Мінімум 4 символи').max(64, 'Максимум 64 символи'),
  email: Yup.string().nullable().email('Невалідна email адреса'),
  discord: Yup.string().nullable().max(128, 'Максимум 128 символів'),
  about_me: Yup.string().max(256, 'Максимум 256 символів'),
  gender: Yup.number().oneOf([1, 2, 3, 4, 5], 'Неприпустима стать'),
  age: Yup.number().nullable().min(5, 'Мінімум 5 років').max(150, 'Максимум 150 років'),
  categories: Yup.array().nullable().max(12, 'Максимум 12 категорій'),
  tags: Yup.array().nullable().max(12, 'Максимум 12 тегів'),
});

const initialValues = {
  name: '',
  email: '',
  discord: '',
  about_me: '',
  gender: '',
  age: 5,
  categories: [],
  tags: [],
};


const UserForm = () => {
  const { categories, tags } = useServerCategories();
  const { userLoading, activeUser, update, isAdmin } = useSession();
  const theme = useTheme();
  const { setConfig } = useSnackbar();
  const formikRef = useRef(null);
  const [isEdit, setIsEdit] = useState(false);

  const isOptionEqualToValue = (option, value) => {
    return option.id === value.id;
  };

  const handleSubmit = async (values, { resetForm, setErrors }) => {
    try {
      const data = {
        ...values,
        categories: values.categories.map(c => ({ id: c.id })),
        tags: values.tags.map(t => ({ id: t.id }))
      }
      const userId = data.id;
      delete data.id;
      delete data.picture;
      const response = await update(data, userId);

      if (!response?.id) {
        const [[, firstValue]] = Object.entries(response);
        if (typeof firstValue === 'string') {
          setErrors(response);
          setConfig({
            severity: 'error', text: firstValue,
          })
        } else if (Array.isArray(firstValue)) {
          setErrors(response);
          setConfig({
            severity: 'error', text: _.head(firstValue),
          })
        } else {
          setConfig({
            severity: 'error', text: 'Помилка оновлення профілю',
          })
          resetForm({ values });
        }
      } else {
        setConfig({
          severity: 'success', text: 'Профіль успішно оновлено',
        })
        setIsEdit(false);
        resetForm({ values: response, about_me: activeUser?.about_me || '' });
      }
    } catch (error) {
      setConfig({
        severity: 'error',
        text: 'Щось пішло не так',
      });
    }
  };

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      validateOnBlur={false}
      initialValues={{ ...(activeUser || initialValues), about_me: activeUser?.about_me || '' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        resetForm,
        dirty,
        isSubmitting,
        isValidating
      }) => (
        <Form
          style={{
            display: 'flex',
            width: '100%',
            maxWidth: 700,
            marginTop: 8,
            marginLeft: 8,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
              backgroundColor: theme.palette.background.paper,
              width: '100%',
              borderRadius: 3,
              p: 3,
            }}
          >
            <FormControl sx={{ flexBasis: '100%', flexGrow: 1, mb: 1, }}>
              <Avatar src={activeUser?.picture} sx={{ width: 100, height: 100 }}>
                <AccountCircleIcon fontSize='large' sx={{ width: 110, height: 110 }} />
              </Avatar>
            </FormControl>
            <FormControl sx={{ flexBasis: 300, flexGrow: 1, minWidth: 200 }}>
              <TextField
                name="name"
                label="Нікнейм"
                disabled={!isEdit}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </FormControl>
            <FormControl
              sx={{
                flexBasis: 150,
                minWidth: 150,
                flexGrow: 1,
              }}
            >
              <InputLabel disabled={!isEdit}>Стать</InputLabel>
              <Select
                name="gender"
                disabled={!isEdit}
                value={values.gender}
                onChange={handleChange}
                onBlur={handleBlur}
                input={
                  <OutlinedInput
                    label="Стать"
                    value={values.gender}
                    error={touched.gender && Boolean(errors.gender)}
                  />
                }
              >
                <MenuItem value={1}>Не хочу вказувати</MenuItem>
                <MenuItem value={2}>Жінка</MenuItem>
                <MenuItem value={3}>Чоловік</MenuItem>
                <MenuItem value={4}>Інший</MenuItem>
                <MenuItem value={5}>Гелікоптер</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ flexBasis: 150, flexGrow: 1, minWidth: 150 }}>
              <TextField
                name="age"
                label="Вік"
                disabled={!isEdit}
                value={values.age}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </FormControl>
            <FormControl sx={{ flexBasis: 300, flexGrow: 1, minWidth: 200 }}>
              <TextField
                name="discord"
                label="Дискорд"
                disabled={!isEdit}
                value={values.discord}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </FormControl>
            <FormControl sx={{ flexBasis: 300, flexGrow: 1, minWidth: 200 }}>
              <TextField
                name="email"
                label="Пошта"
                disabled={!isEdit}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </FormControl>
            <FormControl fullWidth>
              <Autocomplete
                id="categories"
                multiple
                disabled={!isEdit}
                disableCloseOnSelect
                options={categories}
                getOptionKey={(option) => option.slug}
                getOptionLabel={(option) => option.name}
                value={values.categories}
                isOptionEqualToValue={isOptionEqualToValue}
                onChange={(event, newValue) => setFieldValue('categories', newValue)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      disabled={!isEdit}
                      key={option.slug}
                      label={option.name}
                    />
                  ))
                }
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Категорії, які вас цікавлять"
                    fullWidth
                    disabled={!isEdit}
                    placeholder="Категорії, які вас цікавлять"
                    error={touched.categories && Boolean(errors.categories)}
                    helperText={touched.categories && errors.categories}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <Autocomplete
                id="tags"
                multiple
                disableCloseOnSelect
                options={tags}
                disabled={!isEdit}
                getOptionKey={(option) => option.slug}
                getOptionLabel={(option) => option.name}
                value={values.tags}
                isOptionEqualToValue={isOptionEqualToValue}
                onChange={(event, newValue) => setFieldValue('tags', newValue)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      disabled={!isEdit}
                      key={option.slug}
                      label={option.name}
                    />
                  ))
                }
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Теги, які вас цікавлять"
                    fullWidth
                    disabled={!isEdit}
                    placeholder="Теги, які вас цікавлять"
                    error={touched.tags && Boolean(errors.tags)}
                    helperText={touched.tags && errors.tags}
                  />
                )}
              />
            </FormControl>
            <FormControl sx={{ flexBasis: '100%', flexGrow: 1, minWidth: 200 }}>
              <TextField
                disabled={!isEdit}
                name="about_me"
                label="Про себе"
                multiline
                rows={5}
                value={values.about_me}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </FormControl>
            {!isEdit && !isAdmin ? (
              <Button
                type="submit"
                variant="contained"
                onClick={() => setIsEdit(true)}
                sx={{
                  height: 'fit-content',
                  width: 'fit-content',
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.text.primary,
                  borderRadius: 3,
                  mt: 1,
                }}
              >
                Редагувати
              </Button>
            ) : null}
            {isEdit ? (
              <>
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsEdit(false);
                    if (dirty) resetForm({ values: activeUser || initialValues });
                  }}
                  disabled={userLoading || isSubmitting || isValidating}
                  sx={{
                    height: 'fit-content',
                    width: 'fit-content',
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.text.primary,
                    borderRadius: 3,
                    ml: 'auto',
                    mt: 1,
                  }}
                >
                  Відмінити
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    height: 'fit-content',
                    width: 'fit-content',
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.text.primary,
                    borderRadius: 3,
                    mt: 1,
                  }}
                  disabled={userLoading || !dirty || isSubmitting || isValidating}
                >
                  Зберегти
                </Button>
              </>
            ) : null}
          </Box>
        </Form>
      )}
    </Formik>
  )
};

export default UserForm;
