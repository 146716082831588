import { createSlice } from '@reduxjs/toolkit';

const navigation = createSlice({
  name: 'navigation',
  initialState: {
    serverCategories: undefined,
    serverTags: undefined,
    activeServerCategory: undefined,
    activeServerTag: undefined,
    activeServerSearch: undefined,
    activeServerOrder: undefined,
    servers: undefined,
    serversLoading: true,
    serverLoading: false,
    activeServer: undefined,
    selectedServer: undefined,
    selectedServerError: undefined,
    selectedServerStatistics: undefined,
    statisticsTimeframe: 'week',
    selectedServerLoading: undefined,
    activeServersPage: sessionStorage.getItem('moniCoreServersPage') || 1,
    serversTotal: 0,
  },
  reducers: {
    updateServerCategories: (state, action) => {
      state.serverCategories = action.payload;
    },
    updateServerTags: (state, action) => {
      state.serverTags = action.payload;
    },
    updateActiveServerCategory: (state, action) => {
      state.activeServerCategory = action.payload;
    },
    updateActiveServerTag: (state, action) => {
      state.activeServerTag = action.payload;
    },
    updateActiveServerSearch: (state, action) => {
      state.activeServerSearch = action.payload;
    },
    updateActiveServerOrder: (state, action) => {
      state.activeServerOrder = action.payload;
    },
    updateServers: (state, action) => {
      state.servers = action.payload;
    },
    updateServersLoading: (state, action) => {
      state.serversLoading = action.payload;
    },
    updateActiveServer: (state, action) => {
      state.activeServer = action.payload;
    },
    updateActiveServersPage: (state, action) => {
      state.activeServersPage = action.payload;
    },
    updateServersTotal: (state, action) => {
      state.serversTotal = action.payload;
    },
    updateServerLoading: (state, action) => {
      state.serverLoading = action.payload;
    },
    updateSelectedServer: (state, action) => {
      state.selectedServer = action.payload;
    },
    updateSelectedServerError: (state, action) => {
      state.selectedServerError = action.payload;
    },
    updateSelectedServerStatistics: (state, action) => {
      state.selectedServerStatistics = action.payload;
    },
    updateStatisticsTimeframe: (state, action) => {
      state.statisticsTimeframe = action.payload;
    },
    updateSelectedServerLoading: (state, action) => {
      state.selectedServerLoading = action.payload;
    },
  },
});

export const {
  updateServerCategories,
  updateServerTags,
  updateActiveServerCategory,
  updateActiveServerTag,
  updateActiveServerSearch,
  updateActiveServerOrder,
  updateServers,
  updateServersLoading,
  updateActiveServer,
  updateActiveServersPage,
  updateServersTotal,
  updateServerLoading,
  updateSelectedServer,
  updateSelectedServerStatistics,
  updateStatisticsTimeframe,
  updateSelectedServerLoading,
  updateSelectedServerError,
} = navigation.actions;
export default navigation.reducer;