import { useState } from "react";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import HoverTooltip from "../tooltips/HoverTooltip";
import SupportTicket from "../modals/SupportTicket";

const SupportNavigation = () => {
  const theme = useTheme();
  const [supportOpen, setSupportOpen] = useState(false);

  return (
    <>
      <Box
        onClick={() => setSupportOpen(true)}
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          py: 1.5,
        }}
      >
        <HoverTooltip title='Підтримка' placement='top'>
          <SupportAgentIcon
            sx={{
              width: 30,
              height: 30,
              fill: theme.palette.text.secondary
            }}
            fontSize='large'
          />
        </HoverTooltip>
      </Box>
      <SupportTicket open={supportOpen} handleClose={() => setSupportOpen(false)} />
    </>
  )
};

export default SupportNavigation;
