import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from "@mui/material";

const ConfirmationDialog = ({ open, question, handleClose, handleDelete }) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle id="dialog-title">
        {question}
      </DialogTitle>
      <DialogActions>
        <Button
          sx={{
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.main,
            borderRadius: 3,
            textTransform: 'capitalize',
            px: 2,
            m: 1,
          }}
          onClick={handleClose}
        >
          Відмінити
        </Button>
        <Button
          sx={{
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.main,
            borderRadius: 3,
            textTransform: 'capitalize',
            px: 2,
            m: 1,
          }}
          onClick={handleDelete}
          autoFocus
        >
          Підвердити
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
