import * as React from "react";
import { useEffect } from "react";
import { Helmet } from 'react-helmet';
import ServersList from "../components/ServersList";
import ServersFilter from "../components/ServersFilter";
import { APP_DOMAIN } from "../config";
import useServers from "../components/hooks/useServers";

export const Index = () => {
  const {
    changeSelectedServer,
    changeSelectedServerError,
  } = useServers();

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Монікор - Каталог Серверів",
      "url": `${APP_DOMAIN}`,
      "description": "Відкрий для себе каталог найкращих українських серверів для Minecraft, CS2, Discord та інших. Оцінюй, додавай улюблені сервери до списку, та приєднуйся до активних спільнот України."
    });
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    changeSelectedServer(undefined);
    changeSelectedServerError(undefined);
  })

  return (
    <>
      <Helmet>
        <title>Монікор - Каталог Серверів</title>
        <meta name="description"
              content="Відкрий для себе каталог найкращих українських серверів для Minecraft, CS2, Discord та інших. Оцінюй, додавай улюблені сервери до списку, та приєднуйся до активних спільнот України." />
        <meta name="keywords" content="ігрові сервери, каталог серверів, майнкрафт сервери, кс сервери, українські сервери, дискорд сервери, рейтинг серверів, кращі сервери, онлайн ігри, моніторинг серверів, спільноти геймерів" />
        <meta property="og:title" content="Монікор - Каталог Серверів" />
        <meta property="og:url" content={APP_DOMAIN} />
        <meta property="og:keywords" content="ігрові сервери, каталог серверів, майнкрафт сервери, кс сервери, українські сервери, дискорд сервери, рейтинг серверів, кращі сервери, онлайн ігри, моніторинг серверів, спільноти геймерів" />
        <meta property="og:description"
              content="Відкрий для себе каталог найкращих українських серверів для Minecraft, CS2, Discord та інших. Оцінюй, додавай улюблені сервери до списку, та приєднуйся до активних спільнот України." />
      </Helmet>
      <ServersFilter />
      <ServersList />
    </>
  );
};
