import { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import useSession from "../hooks/useSession";
import { makeTheme, themes } from "../../themes";
import { GlobalStyles } from "@mui/material";
import SnackbarProvider from "./SnackbarProvider";
import useServers from "../hooks/useServers";

const SessionProvider = ({ children }) => {
  const { isAuthenticated, checkSession, theme, customTheme } = useSession();
  const { changeUserServers } = useServers();
  const [activeTheme, setActiveTheme] = useState(makeTheme(themes[theme].theme));

  useEffect(() => {
    if (!isAuthenticated) {
      sessionStorage.removeItem('csrftoken');
      checkSession();
    }
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      changeUserServers(undefined);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (theme === 'custom' && customTheme) setActiveTheme(makeTheme(createTheme(themes[theme].theme, customTheme)))
    else setActiveTheme(makeTheme(themes[theme].theme));
    localStorage.setItem('moniCoreTheme', theme);
  }, [theme, customTheme]);

  return (
    <ThemeProvider theme={activeTheme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: activeTheme.palette.background.default,
            color: activeTheme.palette.text.primary,
          },
          '*': {
            color: activeTheme.palette.text.primary,
          },
        }}
      />
      {children}
      <SnackbarProvider />
    </ThemeProvider>
  );
};

export default SessionProvider;