import React from "react";

const MonobaseIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      version="1"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M1730 5109c-643-18-967-85-1215-252-287-194-428-497-481-1037-35-357-44-1868-14-2340C56 908 150 615 369 387 565 183 837 80 1300 34c357-35 1868-44 2340-14 572 36 865 130 1093 349 204 196 307 468 353 931 35 357 44 1868 14 2340-36 572-130 865-349 1093-196 205-469 308-936 353-315 30-1397 43-2085 23zm1740-1197c141-43 216-88 315-187 140-140 195-269 195-460 0-199-80-377-237-526-100-96-197-150-308-173l-59-13 65-32c311-153 400-573 195-921-117-200-301-328-563-392-86-21-105-22-1022-25l-934-4 6 28c3 15 141 633 306 1373 166 739 301 1348 301 1352 0 5 375 7 833 6l832-4 75-22z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
      <path
        d="M2330 3337c0-1-22-100-50-220-27-120-50-222-50-228 0-16 763-6 813 10s111 72 136 125c25 50 28 149 7 200-18 43-78 92-132 105-37 9-724 17-724 8zM2085 2248c-16-69-105-461-105-464 0-2 168-4 373-4 261 0 390 4 433 13 155 33 244 145 232 292-6 70-45 129-107 162-44 23-45 23-432 23h-389l-5-22z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
    </svg>
  );
}

export default MonobaseIcon;
