export const getTotalPages = (size, rowsPerPage) => {
  return Math.ceil(size / rowsPerPage);
};

export const formatNumber = (number, minimumFractionDigits = 2) => {
    if (!number) return 0;

    return number.toLocaleString('en-US', {
        minimumFractionDigits,
        maximumFractionDigits: 2,
        useGrouping: true
    }).replace(/,/g, ' ').replace(/\./g, ',');
}
