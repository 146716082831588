import React from "react";

const MonojarIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      version="1"
      viewBox="0 0 96 96"
      {...props}
    >
      <path
        d="M0 480V0h960v960H0V480zm480 175c15-8 30-20 32-27 3-9 13-6 32 9 38 30 78 37 119 19 46-19 57-56 57-198V350h-90v104c0 57-3 111-6 120-8 21-53 21-82 0-20-14-22-23-22-120V350h-80v108c0 116-6 132-49 132-51 0-61-23-61-137V350h-80v310h40c29 0 40-4 40-15 0-19-2-19 45 5s67 25 105 5z"
        transform="matrix(.1 0 0 -.1 0 96)"
      ></path>
    </svg>
  );
}

export default MonojarIcon;
