import { createTheme } from '@mui/material/styles';
import Color from 'color';

const coreTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#2A415D',
    },
    secondary: {
      main: '#37474F',
    },
    background: {
      default: '#0a0a0a',
      paper: '#1f1f1f',
    },
    text: {
      primary: '#F5F5F5',
      secondary: '#d6d6d6',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
});

const moniCoreCustomTheme = localStorage.getItem('moniCoreCustomTheme');
const customTheme = createTheme(coreTheme, moniCoreCustomTheme ? JSON.parse(moniCoreCustomTheme) : {})

export const themes = {
  core: { name: 'Core', theme: coreTheme, color: coreTheme.palette.primary.main },
  custom: { name: 'Custom', theme: customTheme, color: customTheme.palette.primary.main },
};

export const getContrastColor = (colorString) => {
  const lightColor = Color('#ffffff');
  const darkColor = Color('#000000');

  const colorInstance = Color(colorString);

  const darkContrast = colorInstance.contrast(darkColor);
  const lightContrast = colorInstance.contrast(lightColor);

  return darkContrast > lightContrast ? darkColor.hex() : lightColor.hex();
};

export const getTransparentColor = (color, alpha = 0.5) => {
  const colorInstance = Color(color);
  return colorInstance.alpha(alpha).rgb().string();
};

export const makeTheme = (theme) => createTheme(theme, {
  palette: {
    divider: theme.palette.background.default,
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
          borderColor: `${theme.palette.background.paper} !important`,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: theme.palette.text.primary,
          '&.Mui-disabled': {
            color: `${getTransparentColor(theme.palette.text.primary)} !important`,
            backgroundColor: `${getTransparentColor(theme.palette.primary.main)}  !important`,
          }
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '8px !important',
          borderColor: theme.palette.action.disabled,
          backgroundColor: getTransparentColor(theme.palette.background.default),
          boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
        },
        input: {
          borderRadius: '8px !important',
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderRadius: '8px !important',
        },
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
        },
        asterisk: {
          color: theme.palette.error.main
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
        },
      }
    }
  },
});
