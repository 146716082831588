import * as yup from "yup";
import React from "react";
import { InputField, SelectField } from "../fields/serverFields";

const defaultSchema = {
  name: yup.string().required("Назва обов'язкова").min(4, 'Мінімум 4 символи').max(64, 'Максимум 60 символів'),
  short_description: yup.string().required("Короткий опис обов'язковий").max(128, 'Максимум 128 символів'),
  description: yup.string().required("Детальний опис обов'язковий").max(15000, 'Максимум 15000 символів (з урахуванням форматування)'),
  category: yup.object().nullable().required("Категорія обов'язкова"),
  tags: yup.array().min(1, 'Оберіть мінімум 1 тег').max(12, 'Максимум 12 тегів'),
  logo: yup.mixed().nullable().notRequired()
    .test("fileSize", "Файл надто великий, максимум 2 Мб", value => !value || (value.size <= 2 * 1024 * 1024))
    .test("fileType", "Недопустиме розширення файлу. Дозволені розширення: png, webp, svg", value => !value || ['image/webp', 'image/png', 'image/svg'].includes(value.type)),
  banner: yup.mixed().nullable().notRequired()
    .test("fileSize", "Файл надто великий, максимум 2 Мб", value => !value || (value.size <= 2 * 1024 * 1024))
    .test("fileType", "Недопустиме розширення файлу. Дозволені розширення: png, webp, svg", value => !value || ['image/webp', 'image/png', 'image/svg'].includes(value.type)),
  status: yup.number().nullable(),
}

export const buildDynamicFields = (config, values, setFieldValue, touched, errors) => {
  return <>
    {config?.discord_invite ? <InputField required={config?.discord_invite?.required} name="discord_url" label="Запрошення" value={values.discord_url} placeholder="Запрошення" setFieldValue={setFieldValue} touched={touched.discord_url} error={errors.discord_url} /> : null}
    {config?.main_ip ? <InputField required={config?.main_ip?.required} name="main_ip" label="IP" value={values.main_ip} placeholder="IP" setFieldValue={setFieldValue} touched={touched.main_ip} error={errors.main_ip} /> : null}
    {config?.version_from ? <SelectField required={config?.version_from?.required} name="version_from" label="Версія від" value={values.version_from} options={config.version_from.options} setFieldValue={setFieldValue} touched={touched.version_from} error={errors.version_from} /> : null}
    {config?.version_to ? <SelectField required={config?.version_to?.required} name="version_to" label="Версія до" value={values.version_to} options={config.version_to.options} setFieldValue={setFieldValue} touched={touched.version_to} error={errors.version_to} /> : null}
    {config?.query_port ? <InputField isSmall required={config?.query_port?.required} name="query_port" label="Порт для запитів" value={values.query_port} placeholder="Порт для запитів" setFieldValue={setFieldValue} touched={touched.query_port} error={errors.query_port} /> : null}
    {config?.additional_ip ? <InputField required={config?.additional_ip?.required} name="additional_ip" label={`${config?.additional_ip?.label} IP`} value={values.additional_ip} placeholder={`${config?.additional_ip?.label} IP`} setFieldValue={setFieldValue} touched={touched.additional_ip} error={errors.additional_ip} /> : null}
    {config?.online_map ? <InputField required={config?.online_map?.required} name="map_url" label="Онлайн мапа" value={values.map_url} placeholder="Онлайн мапа" setFieldValue={setFieldValue} touched={touched.map_url} error={errors.map_url} /> : null}
  </>;
}

export const getValidationSchema = (config) => {
  const dynamicFields = {};

  if (config?.discord_invite) {
    if (config?.discord_invite?.required) {
      dynamicFields.discord_url = yup.string().matches(/(?:https:\/\/)?(?:discord\.gg\/|discord\.com\/invite\/)([A-Za-z0-9]+)/, "Недопустиме посилання").required("Запрошення обов'язкове").min(4, 'Мінімум 4 символи').max(128, 'Максимум 128 символів')
    } else {
      dynamicFields.discord_url = yup.string().matches(/(?:https:\/\/)?(?:discord\.gg\/|discord\.com\/invite\/)([A-Za-z0-9]+)/, "Недопустиме посилання").max(128, 'Максимум 128 символів')
    }
  }

  if (config?.main_ip) {
    if (config?.main_ip?.required) {
      dynamicFields.main_ip = yup.string().required("IP обов'язкове").min(4, 'Мінімум 4 символи').max(128, 'Максимум 128 символів')
    } else {
      dynamicFields.main_ip = yup.string().nullable().max(128, 'Максимум 128 символів')
    }
  }

  if (config?.online_map) {
    if (config?.online_map?.required) {
      dynamicFields.online_map = yup.string().required("Онлайн мапа обов'язкова").min(4, 'Мінімум 4 символи').max(128, 'Максимум 128 символів')
    } else {
      dynamicFields.online_map = yup.string().nullable().max(128, 'Максимум 128 символів')
    }
  }

  if (config?.query_port) {
    if (config?.query_port?.required) {
      dynamicFields.query_port = yup.number().required("Порт для запитів обов'язковий").positive('Недопустиме значення')
    } else {
      dynamicFields.query_port = yup.number().nullable().positive('Недопустиме значення')
    }
  }

  if (config?.version_from) {
    if (config?.version_from?.required) {
      dynamicFields.version_from = yup.string().required("Версія від обов'язкова")
    } else {
      dynamicFields.version_from = yup.string().nullable()
    }
  }

  if (config?.version_to) {
    if (config?.version_to?.required) {
      dynamicFields.version_to = yup.string().required("Версія до обов'язкова")
    } else {
      dynamicFields.version_to = yup.string().nullable()
    }
  }

  if (config?.additional_ip) {
    if (config?.additional_ip?.required) {
      dynamicFields.additional_ip = yup.string().required(`${config?.additional_ip?.label} IP обов'язкове`).min(4, 'Мінімум 4 символи').max(128, 'Максимум 128 символів')
    } else {
      dynamicFields.additional_ip = yup.string().nullable().max(128, 'Максимум 128 символів')
    }
  }

  return yup.object().shape({
    ...defaultSchema,
    ...dynamicFields,
  });
};