import * as React from "react";
import { getTransparentColor } from "../../themes";
import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import HoverTooltip from "../tooltips/HoverTooltip";
import DiscordIcon from "../../assets/icons/Discord";
import WebsiteIcon from "../../assets/icons/Website";
import YoutubeIcon from "../../assets/icons/Youtube";
import TwitchIcon from "../../assets/icons/Twitch";
import InstagramIcon from "../../assets/icons/Instagram";

const Social = ({ server }) => {
  const theme = useTheme();

  const handleExternalClick = (link) => {
    window.open(link, '_blank');
  }

  if (![server.discord_url && server.main_ip, server.website_url, server.youtube_url, server.twitch_url, server.instagram_url].some(i => Boolean(i))) return null

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: { xs: '100%', md: 'calc(50% - 12px)', lg: '100%' },
        backgroundColor: theme.palette.background.paper,
        boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
        borderRadius: 5,
        p: 2,
        px: 4,
      }}
    >
      <Typography
        variant="h3"
        component="h3"
        sx={{
          fontSize: 20,
          mb: 2
        }}
      >
        Соціальні мережі
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          flexWrap: 'wrap'
        }}
      >
        {server.category.slug !== 'discord' && server?.discord_url ? (
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.main,
              py: 1,
              px: 2,
              borderRadius: 3,
              fontSize: { xs: 14, md: 16 },
              boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
            }}
            onClick={() => handleExternalClick(server?.discord_url)}
          >
            <HoverTooltip title="Дискорд">
              <DiscordIcon fill={theme.palette.text.secondary} />
            </HoverTooltip>
          </Button>
        ) : null}
        {server.website_url ? (
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.main,
              py: 1,
              px: 2,
              borderRadius: 3,
              fontSize: { xs: 12, md: 14 },
              boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
            }}
            onClick={() => handleExternalClick(server?.website_url)}
          >
            <HoverTooltip title="Вебсайт">
              <WebsiteIcon fill={theme.palette.text.secondary} />
            </HoverTooltip>
          </Button>
        ) : null}
        {server.youtube_url ? (
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.main,
              py: 1,
              px: 2,
              borderRadius: 3,
              fontSize: { xs: 12, md: 14 },
              boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
            }}
            onClick={() => handleExternalClick(server?.youtube_url)}
          >
            <HoverTooltip title="Youtube">
              <YoutubeIcon fill={theme.palette.text.secondary} />
            </HoverTooltip>
          </Button>
        ) : null}
        {server.twitch_url ? (
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.main,
              py: 1,
              px: 2,
              borderRadius: 3,
              fontSize: { xs: 12, md: 14 },
              boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
            }}
            onClick={() => handleExternalClick(server?.twitch_url)}
          >
            <HoverTooltip title="Twitch">
              <TwitchIcon fill={theme.palette.text.secondary} />
            </HoverTooltip>
          </Button>
        ) : null}
        {server.instagram_url ? (
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.main,
              py: 1,
              px: 2,
              borderRadius: 3,
              fontSize: { xs: 12, md: 14 },
              boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
            }}
            onClick={() => handleExternalClick(server?.instagram_url)}
          >
            <HoverTooltip title="Instagram">
              <InstagramIcon fill={theme.palette.text.secondary} />
            </HoverTooltip>
          </Button>
        ) : null}
      </Box>
    </Box>
  )
};

export default Social;
