import { SpeedDial, SpeedDialAction } from "@mui/lab";
import { themes } from "../../themes";
import ThemeIcon from "../../assets/icons/Theme";
import HoverTooltip from "../tooltips/HoverTooltip";
import useSession from "../hooks/useSession";
import { useTheme } from "@mui/material";
import ThemeSettings from "../modals/ThemeSettings";
import { useState } from "react";

const ThemeNavigation = ({ open, handleClose }) => {
  const theme = useTheme();
  const { changeTheme, customTheme, theme: currentTheme } = useSession();
  const [themeSettingsOpen, setThemeSettingsOpen] = useState(false);

  const onThemeClick = (newTheme) => {
    changeTheme(newTheme);
  }

  return (
    <>
      <SpeedDial
        ariaLabel='theme'
        open={open}
        onClose={handleClose}
        icon={
          <HoverTooltip title='Тема' placement='left'>
            <ThemeIcon fill={theme.palette.text.secondary}/>
          </HoverTooltip>
        }
        direction='down'
        sx={{
          p: 0,
          '& button': {
            width: '100%',
            height: 50,
            borderRadius: 0,
            boxShadow: 'none',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
            '& svg': {
              width: 30,
              height: 30,
            }
          },
          '& div': {
            mt: '-10px !important',
            pt: '10px !important',
            '& button': {
              m: 0,
              p: 1
            }
          }
        }}
      >
        {Object.entries(themes).map(([key, t]) => (
          <SpeedDialAction
            key={key}
            disableRipple
            onClick={() => {
              onThemeClick(key);
              if (key === 'custom') setThemeSettingsOpen(true);
            }}
            icon={<ThemeIcon
              fill={key === 'custom' && currentTheme === 'custom' && customTheme ? theme.palette.primary.main : t.color} />}
          />
        ))}
      </SpeedDial>
      <ThemeSettings
        open={themeSettingsOpen}
        handleClose={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setThemeSettingsOpen(false)
        }}
      />
    </>
  );
};

export default ThemeNavigation;
