import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FullMenu from "./FullMenu";
import ServerForm from "../forms/ServerForm";
import useServers from "../hooks/useServers";
import useSession from "../hooks/useSession";
import LoadingOverlay from "../placeholders/LoadingOverlay";
import UserServersList from "../UserServersList";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import useSnackbar from "../hooks/useSnackbar";
import HoverTooltip from "../tooltips/HoverTooltip";
import LogoutIcon from "@mui/icons-material/Logout";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DnsIcon from '@mui/icons-material/Dns';
import { CREATE_MODE, EDIT_MODE } from "../../constants";
import UserForm from "../forms/UserForm";
import { getTransparentColor } from "../../themes";
import { useParams } from "react-router-dom";
import useServerCategories from "../hooks/useServerCategories";

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`menu-page-${index}`}
    aria-labelledby={`menu-${index}`}
    {...other}
    style={{
      width: '100%',
      height: 'calc(100vh - 64px)',
      overflowY: 'auto'
    }}
  >
    {value === index ? (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', width: '100%' }}>
        {children}
      </Box>
    ) : null}
  </div>
);

const a11yProps = (index, theme) => {
  return {
    id: `menu-${index}`,
    'aria-controls': `menu-page-${index}`,
    sx: {
      textTransform: 'none',
      textAlign: 'left',
      fontSize: 16,
      justifyContent: 'center',
      color: theme.palette.text.primary,
      alignItems: 'flex-start',
      minWidth: 30,
      minHeight: 40,
      px: 1,
      mr: 0.5
    }
  };
}

const UserSettings = ({ open, handleClose }) => {
  const [activeMenu, setActiveMenu] = useState(0);
  const [resetServerForm, setResetServerForm] = useState(false);
  const [isServerFormOpen, setIsServerFormOpen] = useState(false);
  const [serverFormMode, setServerFormMode] = useState(CREATE_MODE);
  const [serverToEdit, setServerToEdit] = useState(undefined);
  const theme = useTheme();
  const {
    fetchUserServers,
    select: refreshServer,
    create: createServer,
    edit: editServer,
    serverLoading,
    userServersLoading
  } = useServers();
  const { fetch: fetchCategories } = useServerCategories();
  const { activeUser, logout } = useSession();
  const { userServers } = useServers();
  const { setConfig } = useSnackbar();
  const { server } = useParams();

  useEffect(() => {
    setActiveMenu(0);
  }, [activeUser]);

  const handleMenuChange = (event, newValue) => {
    setActiveMenu(newValue);
  };

  const handleServerSave = async (data, serverId) => {
    let response;
    if (serverFormMode === CREATE_MODE) {
      response = await createServer(data);
    }
    if (serverFormMode === EDIT_MODE) {
      response = await editServer(data, serverId);
    }
    if (response?.data?.id) {
      setServerToEdit(undefined);
      setIsServerFormOpen(false);
      fetchUserServers();
      if (server) {
        refreshServer(server);
        fetchCategories();
      }
    }
    return response?.data;
  }

  const handleAddServerOpen = () => {
    if (userServers?.length >= 12) {
      setConfig({
        severity: 'info', text: 'Ви не можете додати більше 12 серверів',
      })
    } else {
      setServerFormMode(CREATE_MODE);
      setServerToEdit(undefined);
      setIsServerFormOpen(true);
    }
  }

  const handleEditServerOpen = (server) => {
    setServerFormMode(EDIT_MODE);
    setServerToEdit({ ...server, logo: null, currentLogo: server.logo, banner: null, currentBanner: server.banner });
    setIsServerFormOpen(true);
  }

  const handleServerFormClose = () => {
    setIsServerFormOpen(false);
    setResetServerForm(!resetServerForm);
  }

  const handleLogout = () => {
    logout();
    handleClose();
  }

  useEffect(() => {
    if (isServerFormOpen) handleServerFormClose();
  }, [activeMenu])

  return (
    <FullMenu open={open} handleClose={handleClose}>
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: theme.palette.background.default,
          display: 'flex',
          height: '100%',
          position: 'relative',
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={activeMenu}
          onChange={handleMenuChange}
          aria-label="profile menu"
          sx={{
            backgroundColor: theme.palette.background.paper,
            pt: 0.5,
          }}
        >
          <Tab
            icon={
              <HoverTooltip title='Профіль' placement='right'>
                <AccountCircleIcon
                  sx={{
                    fill: activeMenu === 0 ? theme.palette.text.secondary : getTransparentColor(theme.palette.text.secondary),
                    width: 30,
                    height: 30
                  }} />
              </HoverTooltip>
            }
            {...a11yProps(0, theme)}
          />
          <Tab
            icon={
              <HoverTooltip title='Сервери' placement='right'>
                <DnsIcon
                  sx={{
                    fill: activeMenu === 1 ? theme.palette.text.secondary : getTransparentColor(theme.palette.text.secondary, 0.3),
                    width: 30,
                    height: 30
                  }} />
              </HoverTooltip>
            }
            {...a11yProps(1, theme)}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              bottom: 0,
              width: '100%',
              top: 'auto',
              zIndex: 1400,
            }}
          >
            <Button
              sx={{
                minWidth: 30,
                minHeight: 50,
                width: '100%',
                p: 1,
                py: 1.5,
                display: 'flex',
                justifyContent: 'center'
              }}
              onClick={handleLogout}
            >
              <HoverTooltip title='Вийти з системи' placement='top'>
                <LogoutIcon sx={{ fill: theme.palette.text.secondary, width: 30, height: 30 }} />
              </HoverTooltip>
            </Button>
          </Box>
        </Tabs>
        <TabPanel value={activeMenu} index={0}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <UserForm />
          </Box>
        </TabPanel>
        <TabPanel value={activeMenu} index={1}>
          {isServerFormOpen ? (
            <>
              <Button
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 3,
                  m: 1,
                  pr: 2,
                  width: 'fit-content',
                }}
                onClick={handleServerFormClose}
              >
                <CloseIcon
                  sx={{
                    fill: theme.palette.text.primary,
                  }}
                />
                Закрити
              </Button>
              <ServerForm mode={serverFormMode} onSave={handleServerSave} reset={resetServerForm}
                          server={serverToEdit} />
            </>
          ) : (
            <>
              <Button
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 3,
                  m: 1,
                  pr: 2,
                  width: 'fit-content',
                }}
                onClick={handleAddServerOpen}
              >
                <AddIcon
                  sx={{
                    fill: theme.palette.text.primary,
                  }}
                />
                Додати
              </Button>
              <UserServersList onEdit={handleEditServerOpen} handleClose={handleClose} />
            </>
          )}
        </TabPanel>
        <LoadingOverlay open={serverLoading || userServersLoading || false} zIndex={1400} />
      </Box>
    </FullMenu>
  )
};

export default UserSettings;
