import * as React from "react";
import { getTransparentColor } from "../../themes";
import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import HoverTooltip from "../tooltips/HoverTooltip";
import MonobaseIcon from "../../assets/icons/Monobase";
import MonojarIcon from "../../assets/icons/Monojar";
import DonatelloIcon from "../../assets/icons/Donatello";
import DiakaIcon from "../../assets/icons/Diaka";

const Donate = ({ server }) => {
  const theme = useTheme();

  const handleExternalClick = (link) => {
    window.open(link, '_blank');
  }

  if (![server.monobase_url, server.monojar_url, server.donatello_url, server.diaka_url].some(i => Boolean(i))) return null

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: { xs: '100%', md: 'calc(50% - 12px)', lg: '100%' },
        backgroundColor: theme.palette.background.paper,
        boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
        borderRadius: 5,
        p: 2,
        px: 4,
      }}
    >
      <Typography
        variant="h3"
        component="h3"
        sx={{
          fontSize: 20,
          mb: 2
        }}
      >
        Підтримати
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1
        }}
      >
        {server.monobase_url ? (
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.main,
              py: 1,
              px: 2,
              borderRadius: 3,
              fontSize: { xs: 12, md: 14 },
              boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
            }}
            onClick={() => handleExternalClick(server?.monobase_url)}
          >
            <HoverTooltip title="Монобаза">
              <MonobaseIcon fill={theme.palette.text.secondary} />
            </HoverTooltip>
          </Button>
        ) : null}
        {server.monojar_url ? (
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.main,
              py: 1,
              px: 2,
              borderRadius: 3,
              fontSize: { xs: 12, md: 14 },
              boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
            }}
            onClick={() => handleExternalClick(server?.monojar_url)}
          >
            <HoverTooltip title="Монобанка">
              <MonojarIcon fill={theme.palette.text.secondary} />
            </HoverTooltip>
          </Button>
        ) : null}
        {server.donatello_url ? (
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.main,
              py: 1,
              px: 2,
              borderRadius: 3,
              fontSize: { xs: 12, md: 14 },
              boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
            }}
            onClick={() => handleExternalClick(server?.donatello_url)}
          >
            <HoverTooltip title="Донателло">
              <DonatelloIcon fill={theme.palette.text.secondary} />
            </HoverTooltip>
          </Button>
        ) : null}
        {server.diaka_url ? (
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.main,
              py: 1,
              px: 2,
              borderRadius: 3,
              fontSize: { xs: 12, md: 14 },
              boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
            }}
            onClick={() => handleExternalClick(server?.diaka_url)}
          >
            <HoverTooltip title="Дяка">
              <DiakaIcon fill={theme.palette.text.secondary} />
            </HoverTooltip>
          </Button>
        ) : null}
      </Box>
    </Box>
  )
};

export default Donate;
