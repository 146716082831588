import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  updateProfile,
  getSession,
  logoutUser,
} from "../../api/api";
import {
  updateActiveUser,
  updateIsAuthenticated,
  updateTheme,
  updateCustomTheme,
  updateActiveUserLoading,
  updateIsAdmin,
} from "../../store/sessionReducer";
import { APP_DOMAIN } from "../../config";
import { useEffect } from "react";
import useSharedState from "./useSharedState";

const useSession = () => {
  const dispatch = useDispatch();
  const activeUser = useSelector((state) => state.session.activeUser);
  const isAuthenticated = useSelector((state) => state.session.isAuthenticated);
  const isAdmin = useSelector((state) => state.session.isAdmin);
  const userLoading = useSelector((state) => state.session.userLoading);
  const theme = useSelector((state) => state.session.theme);
  const customTheme = useSelector((state) => state.session.customTheme);
  const [hasActiveSession, setHasActiveSession] = useSharedState('hasActiveSession');
  const [hasActiveLogin, setHasActiveLogin] = useSharedState('hasActiveLogin');

  const changeActiveUser = (value) => {
    dispatch(updateActiveUser(value));
  }

  const changeIsAuthenticated = (value) => {
    dispatch(updateIsAuthenticated(value));
  }

  const changeIsAdmin = (value) => {
    dispatch(updateIsAdmin(value));
  }

  const changeActiveUserLoading = (value) => {
    dispatch(updateActiveUserLoading(value));
  }

  const changeTheme = (value) => {
    dispatch(updateTheme(value));
  }

  const changeCustomTheme = (value) => {
    dispatch(updateCustomTheme(value));
    localStorage.setItem('moniCoreCustomTheme', JSON.stringify(value));
  }

  const login = (provider) => {
    const width = 600;
    const height = 600;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;
    const loginWindow = window.open(`${APP_DOMAIN}/api/auth/${provider}/login/`, '_blank', `width=${width},height=${height},left=${left},top=${top},resizable,scrollbars`);
    setHasActiveLogin(provider);

    const timer = setInterval(() => {
      if (loginWindow.closed) {
        clearInterval(timer);
      }
    }, 1000);
  }

  const logout = async () => {
    const user = await logoutUser();
    if (user?.data?.is_logged_out) {
      sessionStorage.removeItem('userPicture');
      sessionStorage.setItem('csrftoken', user?.data?.csrf_token);
      localStorage.removeItem('moniCoreToken');
      changeIsAuthenticated(false);
      changeActiveUser(undefined);
    }
  }

  const checkSession = async () => {
    const user = await getSession();
    if (hasActiveLogin && !hasActiveSession) {
      setHasActiveSession(user?.data?.is_authenticated || '');
      window.close();
    } else if (user) {
      changeIsAuthenticated(user?.data?.is_authenticated);
      if (user?.data?.csrf_token) {
        sessionStorage.setItem('csrftoken', user?.data?.csrf_token);
      }
      if (user?.data?.access_token) {
        localStorage.setItem('moniCoreToken', user?.data?.access_token);
      }
      setHasActiveSession('');
      setHasActiveLogin('');
      if (user?.data?.is_authenticated) {
        const account = await getProfile();
        changeActiveUser(account?.data);
        changeIsAdmin(account?.data?.id === 'admin')
      }
    }
  }

  useEffect(() => {
    if (hasActiveSession && hasActiveLogin) {
      setHasActiveLogin('');
      checkSession();
    }
  }, [hasActiveSession]);

  const update = async (data, pk) => {
    changeActiveUserLoading(true);
    const account = await updateProfile(data, pk);
    if (account?.data?.id) {
      changeActiveUser(account?.data);
    }
    changeActiveUserLoading(false);
    return account?.data;
  }

  return {
    activeUser,
    userLoading,
    isAuthenticated,
    isAdmin,
    theme,
    customTheme,
    login,
    logout,
    update,
    checkSession,
    changeTheme,
    changeCustomTheme,
  }
}

export default useSession;