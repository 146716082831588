import React from "react";

const DiakaIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      version="1"
      viewBox="0 0 180 180"
      {...props}
    >
      <path
        d="M552 1489c-64-12-159-67-202-117-79-89-109-194-90-314 14-90 47-160 110-232 27-31 50-66 50-77 0-48 49-99 95-99 8 0 18-12 21-27 9-37 50-81 85-88 20-5 29-14 34-34 7-35 43-69 80-80 20-5 32-17 39-39 19-54 50-77 106-77 47 0 52 3 115 65 36 35 67 72 70 81 4 10 21 19 46 23 46 7 99 53 99 85 0 14 13 26 45 40 37 16 50 29 66 66 13 28 26 45 37 45 31 0 62 30 88 83 13 29 40 76 59 106 61 96 69 215 24 337-30 80-122 172-201 202-118 44-239 37-355-21l-63-31-22 21c-35 32-120 72-179 83s-96 11-157-1zm225-78c28-13 61-33 72-45l22-23-132-127c-148-142-147-139-54-182 96-45 166-24 279 86l72 69 166-167c91-92 170-176 176-187 14-28-2-63-33-71-22-6-42 9-164 125-76 72-146 131-154 131s-18-7-21-16c-5-12 31-55 131-155 130-132 136-140 127-166-8-22-16-28-40-28-27 0-52 20-164 128-130 126-162 145-168 102-2-14 35-58 128-150 133-133 147-156 114-189-24-24-48-19-94 19-23 19-50 35-60 35-11 0-20 10-25 28-9 36-51 80-86 87-20 5-31 15-39 39-13 40-38 64-76 75-20 5-31 17-39 42-14 42-66 79-112 79-28 0-45-10-92-55-32-30-63-55-69-55s-27 21-46 46c-123 161-102 388 45 490 98 67 229 81 336 35zm547-37c66-32 119-87 149-153 33-71 31-197-5-273-15-32-33-58-40-58s-96 83-198 185-189 185-195 185-46-36-90-81c-93-93-118-109-177-109-88 0-84 10 56 146 170 165 232 197 367 190 57-3 90-11 133-32zM641 880c35-19 24-60-33-117-53-54-90-67-116-41-24 24-12 56 41 112s72 65 108 46zm127-123c26-20 12-54-45-111-31-31-64-56-73-56-33 0-55 29-51 67 2 18 62 84 95 104 28 17 48 16 74-4zm112-115c17-14 21-25 17-44-8-29-109-128-131-128-21 0-56 36-56 57s111 133 132 133c9 0 26-8 38-18zm123-123c21-30 11-52-48-109-56-52-83-61-109-34-27 26-18 53 35 110 58 60 96 71 122 33z"
        transform="matrix(.1 0 0 -.1 0 180)"
      ></path>
    </svg>
  );
}

export default DiakaIcon;
