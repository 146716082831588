import * as React from "react";
import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { APP_DOMAIN } from "../config";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Avatar, Typography, useTheme } from "@mui/material";
import useServers from "../components/hooks/useServers";
import LoadingOverlay from "../components/placeholders/LoadingOverlay";
import { useParams } from "react-router-dom";
import { getTransparentColor } from "../themes";
import Button from "@mui/material/Button";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FlagIcon from '@mui/icons-material/Flag';
import ServerTags from "../components/ServerTags";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import HoverTooltip from "../components/tooltips/HoverTooltip";
import useCommunications from "../components/hooks/useCommunications";
import useSnackbar from "../components/hooks/useSnackbar";
import Social from "../components/server/Social";
import Donate from "../components/server/Donate";
import Information from "../components/server/Information";
import Statistics from "../components/server/Statistics";
import SelectedServerEmptyPlaceholder from "../components/placeholders/SelectedServerEmptyPlaceholder";
import { formatNumber } from "../utils";
import ModerationChoice from "../components/server/ModerationChoice";
import Comments from "../components/server/Comments";
import ReportDialog from "../components/modals/ReportDialog";
import useSession from "../components/hooks/useSession";
import _ from "lodash";

export const Server = () => {
  const theme = useTheme();
  const {
    selectedServer,
    selectedServerLoading,
    select,
    changeSelectedServer,
    changeSelectedServerError,
    selectedServerError,
  } = useServers();
  const { server } = useParams();
  const { likeLoading, likeServer, ticketLoading, fetchComments, activePage: activeCommentsPage } = useCommunications();
  const { setConfig } = useSnackbar();
  const { activeUser } = useSession();
  const [reportOpen, setReportOpen] = useState(false);

  const handleLikeServer = async () => {
    const response = await likeServer(selectedServer.id);
    if (response?.likes) {
      changeSelectedServer({ ...selectedServer, likes: response?.likes })
    } else {
      const [[, firstValue]] = Object.entries(response);
      if (typeof firstValue === 'string') {
        setConfig({
          severity: 'error', text: firstValue,
        })
      } else if (Array.isArray(firstValue)) {
        setConfig({
          severity: 'error', text: _.head(firstValue),
        })
      }
    }
  }

  const handleExternalClick = (link) => {
    window.open(link, '_blank');
  }

  useEffect(() => {
    if (server) {
      select(server);
    } else {
      changeSelectedServerError(undefined);
    }
  }, [server]);

  useEffect(() => {
    if (selectedServer?.id) fetchComments(selectedServer.id, activeCommentsPage);

    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": `${selectedServer?.name}`,
      "image": `${APP_DOMAIN}/${selectedServer?.banner || selectedServer?.logo}`,
      "url": `${APP_DOMAIN}/server/${selectedServer?.slug}`,
      "description": `${selectedServer?.short_description}`
    });
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [selectedServer]);

  if (server && selectedServerError && !selectedServerLoading) return <SelectedServerEmptyPlaceholder />;

  return (
    <>
      <Helmet>
        <title>{selectedServer?.name}</title>
        <meta name="title" content={selectedServer?.name} />
        <meta name="description" content={selectedServer?.short_description} />
        <meta name="keywords" content={selectedServer?.category?.keywords} />
        <meta property="og:title" content={selectedServer?.name} />
        <meta property="og:url" content={`${APP_DOMAIN}/server/${selectedServer?.slug}`} />
        <meta property="og:description" content={selectedServer?.short_description} />
        <meta property="og:image" content={`${APP_DOMAIN}/${selectedServer?.banner || selectedServer?.logo}`} />
        <link rel="canonical" href={`${APP_DOMAIN}/server/${selectedServer?.slug}`} />
      </Helmet>
      <Container
        maxWidth="none"
        id="server"
        sx={{
          p: '1em 70px 2em 70px !important',
          width: "100%",
          overflow: "hidden",
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {selectedServer ? (
          <>
            <Box
              sx={{
                backgroundImage: `url(${selectedServer.banner})`,
                backgroundColor: theme.palette.background.paper,
                backgroundSize: 'cover',
                backgroundPosition: 'top left',
                backgroundRepeat: 'no-repeat',
                width: '100%',
                borderRadius: 3,
                position: 'relative',
                height: { xs: 250, md: 350 },
                boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
              }}
            />
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexWrap: 'wrap',
                alignItems: { xs: 'center', sm: 'flex-start' },
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: 'fit-content',
                  alignItems: { xs: 'center', sm: 'flex-start' },
                  flexDirection: { xs: 'column', sm: 'row' },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      backgroundColor: theme.palette.background.default,
                      borderRadius: 5,
                      p: 1,
                      height: 'fit-content',
                      width: 'fit-content',
                      boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
                      mt: { xs: '-35%', md: '-40%' },
                      justifyContent: { xs: 'center', sm: 'flex-start' },
                      zIndex: 2,
                      mx: 5,
                    }}
                  >
                    <Avatar
                      src={selectedServer.logo || selectedServer.category.icon}
                      sx={{
                        textAlign: { xs: 'center', sm: 'left' },
                        borderRadius: 3,
                        width: { xs: 100, md: 150 },
                        height: { xs: 100, md: 150 },
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    width: 'fit-content',
                    justifyContent: { xs: 'center', sm: 'flex-start' },
                    pt: 3,
                    px: 1,
                  }}
                >
                  <Typography
                    variant="h3"
                    component="h3"
                    sx={{
                      fontSize: { xs: 20, md: 30 },
                      fontWeight: theme.typography.fontWeightBold,
                      color: theme.palette.text.primary,
                      textAlign: 'center'
                    }}
                  >
                    {selectedServer.name}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  ml: { xs: 1, sm: 'auto' },
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  pt: 3,
                  gap: 2
                }}
              >
                {selectedServer.connect ? (
                  <Button
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      py: 1,
                      px: 2,
                      borderRadius: 3,
                      fontSize: { xs: 14, md: 16 },
                      boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
                    }}
                    onClick={() => handleExternalClick(selectedServer?.connect)}
                  >
                    Приєднатися
                    <OpenInNewIcon fill={theme.palette.text.secondary} sx={{ fontSize: 20, ml: 1 }} />
                  </Button>
                ) : null}
                <Button
                  sx={{
                    backgroundColor: theme.palette.secondary.main,
                    py: 1,
                    px: 2,
                    borderRadius: 3,
                    fontSize: { xs: 14, md: 16 },
                    boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
                  }}
                  disabled={likeLoading}
                  onClick={handleLikeServer}
                >
                  <HoverTooltip title="Вподобайка">
                    <FavoriteIcon fill={theme.palette.text.primary} sx={{ fontSize: 24 }} />
                  </HoverTooltip>
                </Button>
                <Button
                  sx={{
                    backgroundColor: theme.palette.secondary.main,
                    py: 1,
                    px: 2,
                    borderRadius: 3,
                    fontSize: { xs: 14, md: 16 },
                    boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
                  }}
                  disabled={ticketLoading || activeUser?.id === selectedServer?.owner}
                  onClick={() => setReportOpen(true)}
                >
                  <HoverTooltip title="Скарга">
                    <FlagIcon fill={theme.palette.text.primary} sx={{ fontSize: 24 }} />
                  </HoverTooltip>
                </Button>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: theme.palette.secondary.main,
                    py: 1,
                    px: 2,
                    pr: 2.5,
                    borderRadius: 3,
                    fontSize: { xs: 14, md: 16 },
                    boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
                  }}
                >
                  <HoverTooltip title="Рейтинг">
                    <EmojiEventsIcon fill={theme.palette.text.primary} sx={{ fontSize: 24 }} />
                    <Typography
                      variant="h2"
                      component="h2"
                      sx={{
                        fontSize: { xs: 14, md: 16 },
                        ml: 1,
                        fontWeight: theme.typography.fontWeightBold,
                        color: theme.palette.text.primary,
                        cursor: 'default',
                      }}
                    >
                      {formatNumber(selectedServer.rating)}
                    </Typography>
                  </HoverTooltip>
                </Box>
                {server.moderation_choice ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: theme.palette.secondary.main,
                      py: 1,
                      px: 2,
                      pr: 2.5,
                      borderRadius: 3,
                      fontSize: { xs: 14, md: 16 },
                      boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
                    }}
                  >
                    <ModerationChoice server={selectedServer} size={30} />
                  </Box>
                ) : null}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: { xs: 'column', lg: 'row' },
                gap: 3,
                mt: 3,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexBasis: { xs: '100%', lg: '70%' },
                  order: { xs: 2, lg: 1 },
                  flexDirection: 'column',
                  gap: 3,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
                    borderRadius: 5,
                    p: 2,
                    px: 4,
                  }}
                >
                  <Typography
                    variant="h3"
                    component="h3"
                    sx={{
                      fontSize: 20,
                      mb: 2
                    }}
                  >
                    Опис сервера
                  </Typography>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      '& *': {
                        m: 0,
                        my: 0.2,
                        textWrap: 'wrap',
                        whiteSpaceCollapse: 'break-spaces',
                      },
                      '& ul, & ol': {
                        pl: 5,
                      },
                      '& a': {
                        color: theme.palette.primary.main,
                        mx: 1
                      },
                      '& pre.ql-syntax': {
                        p: 1,
                        display: 'flex',
                        backgroundColor: theme.palette.background.default,
                      },
                      '& p': {
                        width: '100%',
                        fontSize: 16,
                        '.ql-size-small': {
                          fontSize: 12,
                        },
                        '.ql-size-large': {
                          fontSize: 24,
                        },
                        '.ql-size-huge': {
                          fontSize: 40,
                        },
                      }
                    }}
                    dangerouslySetInnerHTML={{ __html: selectedServer.description }}
                  />
                </Box>
                <Statistics />
                <Comments />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexBasis: { xs: '100%', lg: '30%' },
                  flexDirection: { xs: 'column', md: 'row', lg: 'column' },
                  flexWrap: 'wrap',
                  order: { xs: 1, lg: 2 },
                  gap: 3,
                }}
              >
                <Information server={selectedServer} />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: { xs: '100%', md: 'calc(50% - 12px)', lg: '100%' },
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
                    borderRadius: 5,
                    p: 2,
                    px: 4,
                  }}
                >
                  <Typography
                    variant="h3"
                    component="h3"
                    sx={{
                      fontSize: 20,
                      mb: 2
                    }}
                  >
                    Теги
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <ServerTags tags={selectedServer.tags} />
                  </Box>
                </Box>
                <Social server={selectedServer} />
                <Donate server={selectedServer} />
              </Box>
            </Box>
          </>
        ) : null}
      </Container>
      <ReportDialog open={reportOpen} handleClose={() => setReportOpen(false)} instanceId={selectedServer?.id} instanceType="server" />
      <LoadingOverlay loading={selectedServerLoading} />
    </>
  );
};
