import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Avatar, useTheme } from "@mui/material";
import Login from "../menus/Login";
import { useEffect, useState } from "react";
import useSession from "../hooks/useSession";
import UserSettings from "../modals/UserSettings";
import Box from "@mui/material/Box";

const UserNavigation = () => {
  const theme = useTheme();
  const { activeUser, isAuthenticated } = useSession();
  const [userPicture, setUserPicture] = useState(sessionStorage.getItem('userPicture'));
  const [profileOpen, setProfileOpen] = useState(false);

  useEffect(() => {
    setUserPicture(activeUser?.picture || sessionStorage.getItem('userPicture'));
  }, [activeUser])

  const [loginAnchorEl, setLoginAnchorEl] = useState(null);
  const loginOpen = Boolean(loginAnchorEl);
  const handleLoginOpen = (event) => {
    setLoginAnchorEl(event.currentTarget);
  };
  const handleLoginClose = () => {
    setLoginAnchorEl(null);
  };

  const handleOnUserClick = (event) => {
    if (!isAuthenticated) handleLoginOpen(event);
    else setProfileOpen(true);
  }

  useEffect(() => {
    if (activeUser) {
      setUserPicture(activeUser.picture);
      sessionStorage.setItem('userPicture', activeUser.picture);
    }
  }, [activeUser]);

  return (
    <>
      <Box onClick={handleOnUserClick}>
        <Avatar
          src={userPicture}
          sx={{ width: 30, height: 30, m: 1, backgroundColor: 'transparent', '& svg': { color: theme.palette.primary.main } }}
        >
          <AccountCircleIcon sx={{ fill: theme.palette.primary.main }} fontSize='large' />
        </Avatar>
      </Box>
      <Login open={loginOpen} handleClose={() => handleLoginClose()} anchorEl={loginAnchorEl} />
      <UserSettings open={profileOpen} handleClose={() => setProfileOpen(false)} />
    </>
  )
};

export default UserNavigation;
