import React from "react";

const DiscordIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      version="1"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M1690 4485c-317-70-559-148-858-275-49-21-57-30-126-139-330-522-544-1059-641-1610-50-287-59-412-58-766 1-181 5-355 10-385l8-54 130-89c325-221 663-391 1017-512 73-25 138-43 144-41 25 10 274 407 274 437 0 4-26 17-57 29-79 31-335 158-352 175s-13 23 41 62l43 31 100-43c528-225 1131-298 1690-204 257 43 491 114 766 232 35 15 36 15 80-20 24-19 44-40 44-46 0-14-209-124-320-168-44-18-84-36-89-40-20-18 239-448 270-449 7 0 65 18 130 41 369 127 702 294 1029 516l130 89 7 54c3 30 9 159 13 285 28 882-200 1686-702 2477l-74 116-137 57c-253 105-583 205-826 250l-90 17-17-24c-10-12-42-74-71-137l-53-113-50 6c-27 3-93 11-145 18-124 16-645 16-775 0-55-6-122-14-150-18l-50-5-65 133c-76 155-63 148-220 113zm204-1649c131-64 226-189 262-343 20-84 14-223-14-304-115-344-492-448-737-204-160 160-199 419-95 632 57 115 150 199 269 240 55 20 80 23 154 20 76-4 98-9 161-41zm1702-1c171-84 274-259 274-465 0-150-47-274-142-375-260-279-680-133-764 265-44 209 47 442 216 550 82 53 151 71 253 66 77-3 98-8 163-41z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
    </svg>
  );
}

export default DiscordIcon;
