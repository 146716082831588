import { useState } from "react";
import Box from "@mui/material/Box";
import DoneIcon from '@mui/icons-material/Done';
import { getContrastColor, getTransparentColor } from "../themes";
import { Chip, useTheme } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HoverTooltip from "./tooltips/HoverTooltip";
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

const ServerIpVersion = ({ server }) => {
  const theme = useTheme();
  const [copied, setCopied] = useState(false);

  if (!server.main_ip) return null;

  const handleCopy = () => {
    navigator.clipboard.writeText(server.main_ip)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(err => console.error("Не вдалося скопіювати!", err));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: { xs: 'center', sm: 'flex-start' },
          gap: 0.5,
        }}
      >
        <Chip
          icon={
            <HoverTooltip placement="bottom" title="Копіювати">
              {copied ? (
                <DoneIcon sx={{ fill: theme.palette.text.secondary, fontSize: 16 }} />
              ) : (
                <ContentCopyIcon
                  sx={{ cursor: 'pointer', fill: theme.palette.text.secondary, fontSize: 16 }}
                  onClick={handleCopy}
                />
              )}
            </HoverTooltip>
          }
          label={server.main_ip}
          sx={{
            borderRadius: 1,
            p: 0.5,
            height: 'auto',
            color: getContrastColor(theme.palette.secondary.main),
            backgroundColor: theme.palette.secondary.main
          }}
        />
        {server.version_from || server.version_to || server.server_status.version ? (
          <Chip
            label={
              <>
                {server.version_from ? server.version_from : null}
                {server.version_from && server.version_to ? ' - ' : null}
                {server.version_to ? server.version_to : null}
                {!server.version_from && !server.version_to && server.server_status.version ? server.server_status.version : null}
              </>
            }
            sx={{
              borderRadius: 1,
              p: 0.5,
              height: 'auto',
              color: getContrastColor(theme.palette.secondary.main),
              backgroundColor: theme.palette.secondary.main
            }}
          />
        ) : null}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderRadius: 1,
            p: 0.5,
            height: 'auto',
            color: getContrastColor(theme.palette.secondary.main),
            backgroundColor: theme.palette.secondary.main,
            width: 'fit-content'
          }}
        >
          <HoverTooltip placement='bottom' title={server.server_status.is_online ? "Онлайн" : "Офлайн"}>
            <GpsFixedIcon
              sx={{
                fill: getTransparentColor(server.server_status.is_online ? theme.palette.success.main : theme.palette.error.main, 0.7),
                fontSize: 16
              }} />
          </HoverTooltip>
        </Box>
      </Box>
    </Box>
  )

}

export default ServerIpVersion;
