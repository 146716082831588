import { useMemo } from "react";
import Box from "@mui/material/Box";
import BronzeIcon from "../../assets/medals/Bronze.png";
import SilverIcon from "../../assets/medals/Silver.png";
import GoldIcon from "../../assets/medals/Gold.png";
import HoverTooltip from "../tooltips/HoverTooltip";

const ModerationChoice = ({ server, size = 24 }) => {

  const medal = useMemo(() => {
    switch (server.moderation_choice) {
      case 1:
        return (
          <HoverTooltip title="Вибір модерації - Бронза" placement="top">
            <img src={BronzeIcon} alt="Золото" style={{ width: size, height: size }} />
          </HoverTooltip>
        );
      case 2:
        return (
          <HoverTooltip title="Вибір модерації - Срібло" placement="top">
            <img src={SilverIcon} alt="Золото" style={{ width: size, height: size }} />
          </HoverTooltip>
        );
      case 3:
        return (
          <HoverTooltip title="Вибір модерації - Золото" placement="top">
            <img src={GoldIcon} alt="Золото" style={{ width: size, height: size }} />
          </HoverTooltip>
        );
      default:
        return null;
    }
  }, [server]);

  if (!medal) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mr: 0.1,
        width: 'fit-content',
      }}
    >
      {medal}
    </Box>
  )
};

export default ModerationChoice;
