import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTheme } from "@mui/material";
import moment from "moment";
import 'moment/locale/uk';
import { formatNumber } from "../../utils";

Highcharts.setOptions({
  lang: {
    months: [
      'Січень', 'Лютий', 'Березень', 'Квітень',
      'Травень', 'Червень', 'Липень', 'Серпень',
      'Вересень', 'Жовтень', 'Листопад', 'Грудень'
    ],
    weekdays: [
      'Неділя', 'Понеділок', 'Вівторок', 'Середа',
      'Четвер', 'П’ятниця', 'Субота'
    ],
    shortMonths: [
      'Січ', 'Лют', 'Бер', 'Квіт',
      'Трав', 'Чер', 'Лип', 'Сер',
      'Вер', 'Жов', 'Лис', 'Гру'
    ],
  },
});

const MembersChart = ({ data, isShowMax = false }) => {
  moment.locale('uk');
  const theme = useTheme();
  const { onlineData, maxData } = useMemo(() => {
    const onlineData = [];
    const maxData = [];
    (data || []).forEach(p => {
      const pointDate = new Date(p.timestamp);
      onlineData.push({ x: pointDate, y: p.online })
      maxData.push({ x: pointDate, y: p.max })
    })
    return {
      onlineData,
      maxData
    }
  }, [data]);

  const series = [
    {
      name: 'Онлайн',
      data: onlineData,
      color: theme.palette.secondary.main,
      fillOpacity: 0.3,
      type: 'area',
    }
  ]

  if (isShowMax) {
    series.push({
      name: 'Учасників',
      data: maxData,
      color: theme.palette.primary.main,
      fillOpacity: 0.3,
      type: 'area',
    })
  }

  const options = {
    chart: {
      marginTop: 30,
      type: 'area',
      backgroundColor: 'transparent',
    },
    title: null,
    legend: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      title: null,
      gridLineWidth: 0,
      labels: {
        style: {
          color: theme.palette.text.primary,
        },
      },
    },
    yAxis: {
      title: null,
      gridLineWidth: 0,
      labels: {
        style: {
          color: theme.palette.text.primary,
        },
      },
    },
    series,
    tooltip: {
      shared: true,
      backgroundColor: theme.palette.background.default,
      style: {
        color: theme.palette.text.primary,
      },
      borderColor: theme.palette.divider,
      formatter: function () {
        return `${moment(this.x).format('DD.MM.YY HH:mm')}<br/>${isShowMax ? `<b>Учасників:</b> ${formatNumber(this.points[1].y, 0)}<br/>` : ''}<b>Онлайн:</b> ${formatNumber(this.points[0].y, 0)}`;
      },
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />
};

export default MembersChart;
