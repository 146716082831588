import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from 'react-redux';
import NotFoundPage from "./pages/special/NotFoundPage";
import ErrorPage from "./pages/special/ErrorPage";
import { Index } from "./pages/Index";
import store from './store/store';
import SessionProvider from "./components/providers/SessionProvider";
import NavigationLayout from "./components/NavigationLayout";
import { Server } from "./pages/Server";

const router = createBrowserRouter([
  {
    path: '/',
    element: <NavigationLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        id: "index",
        element: <Index />,
        children: [
          {
            path: ":category",
            id: "category",
            element: <Index />,
            children: [
              {
                path: "tag/:tag",
                id: "category_tag",
                element: <Index />,
              },
            ]
          },
          {
            path: "tag/:tag",
            id: "tag",
            element: <Index />,
          }
        ],
      },
      {
        path: "server/:server",
        id: "server",
        element: <Server />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      }
    ]
  },
]);

export const AppRouter = () => {
  return (
    <Provider store={store}>
      <SessionProvider>
        <RouterProvider router={router} />
      </SessionProvider>
    </Provider>
  );
};