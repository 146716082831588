import React from 'react';
import { Backdrop, Box, useTheme } from '@mui/material';
import { Skeleton } from "@mui/lab";
import { getTransparentColor } from "../../themes";

const LoadingOverlay = ({ open, zIndex }) => {
  const theme = useTheme();

  return (
    <Backdrop
      sx={{
        backgroundColor: getTransparentColor(theme.palette.background.paper, 0.3),
        zIndex: zIndex || 10,
      }}
      open={open}
    >
      <Box sx={{ width: '30%' }}>
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} animation="wave" />
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      </Box>
    </Backdrop>
  );
};

export default LoadingOverlay;