import React, { useEffect, useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { Box, FormControl, InputLabel, Typography, useTheme } from '@mui/material';
import { getTransparentColor } from "../themes";
import { CREATE_MODE } from "../constants";

const MuiEditor = ({ label, required, placeholder, error, touched, value, onChange, mode }) => {
  const [editorValue, setEditorValue] = useState("");
  const [focused, setFocused] = useState(false);
  const [labelPosition, setLabelPosition] = useState(`translate(15px, ${mode === CREATE_MODE ? 72 : 7}px) scale(${mode === CREATE_MODE ? 1 : 0.75})`);
  const editorRef = useRef(null);
  const labelRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    if (value !== "<p><br></p>") {
      setEditorValue(value);
    }
  }, [value]);

  const updateLabelPosition = () => {
    if (editorRef.current && labelRef.current) {
      const editorElement = editorRef.current.querySelector('.ql-editor > *');
      const labelElement = labelRef.current;

      if (editorElement && labelElement) {
        const editorRect = editorElement.getBoundingClientRect();
        const labelRect = labelElement.getBoundingClientRect();

        const difference = editorRect.top - labelRect.top;

        if (focused || editorValue) {
          setLabelPosition(`translate(15px, 7px) scale(0.75)`);
        } else {
          setLabelPosition(`translate(15px, ${Math.ceil(difference)}px) scale(1)`);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateLabelPosition);
    updateLabelPosition();

    return () => {
      window.removeEventListener('resize', updateLabelPosition);
    };
  }, [editorValue, focused]);

  const handleEditorChange = (content) => {
    setEditorValue(content !== "<p><br></p>" ? content : "");
    if (onChange) {
      onChange(content !== "<p><br></p>" ? content : "");
    }
  };

  const handleFocus = () => {
    setFocused(true);
    updateLabelPosition();
  };

  const handleBlur = () => {
    setFocused(false);
    updateLabelPosition();
  };

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'code-block'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    ['clean']
  ];

  return (
    <FormControl ref={labelRef} sx={{ width: '100%', mt: '-16px' }} error={touched && Boolean(error)}>
      <Box
        sx={{
          top: 9,
          left: 9,
          position: 'absolute',
          width: 115,
          height: 16,
          visibility: Boolean(editorValue) || focused ? 'visible' : 'hidden',
          opacity: Boolean(editorValue) || focused ? 1 : 0,
          transition: Boolean(editorValue) || focused ? 'opacity 0.2s ease-in-out, visibility 0s 0.2s' : 'opacity 0.2s ease-in-out',
          background: `linear-gradient(to bottom, ${theme.palette.background.paper} 50%, transparent 50%)`,
        }}
      />
      <InputLabel
        required={required}
        error={touched && Boolean(error)}
        sx={{
          transition: 'all 0.2s ease-in-out',
          transform: labelPosition,
          width: 'fit-content',
          color: touched && Boolean(error) ? theme.palette.error.main : focused ? theme.palette.primary.main : theme.palette.text.primary,
          pointerEvents: 'none',
        }}
      >
        {label}
      </InputLabel>

      <Box
        mt={2}
        ref={editorRef}
        sx={{
          minHeight: '200px',
          border: `1px solid ${touched && Boolean(error) ? theme.palette.error.main : theme.palette.action.disabled}`,
          backgroundColor: getTransparentColor(theme.palette.background.default),
          boxShadow: `0 6px 6px -4px ${getTransparentColor('#000000')}`,
          borderRadius: '8px',
          '&:hover': {
            borderColor: theme.palette.text.primary,
          },
          '&:focus-within': {
            borderColor: theme.palette.primary.main,
            boxShadow: `${theme.palette.primary.main} 0 0 0 1px`,
          },
          '& .ql-container': {
            display: 'flex',
            flexDirection: 'column',
            border: 'none',
            minHeight: '200px',
            height: '100%',
            color: theme.palette.text.primary,
            '& .ql-editor': {
              order: 2,
              fontFamily: theme.typography.fontFamily,
              minHeight: '200px',
              height: '100%',
              fontSize: 16,
              '&.ql-blank': {
                fontSize: '16px !important',
              },
              '& .ql-size-small': {
                fontSize: 12,
              },
              '& .ql-size-large': {
                fontSize: 24,
              },
              '& .ql-size-huge': {
                fontSize: 40,
              },
              '&::before': {
                color: theme.palette.text.secondary,
                opacity: 0.5,
                fontStyle: 'normal',
                fontSize: '1rem',
              },
            },
          },
          '& .ql-tooltip': {
            position: 'relative',
            display: 'flex',
            order: 1,
            mt: '-6px',
            mb: 1,
            width: '100%',
            left: '0 !important',
            top: '0 !important',
            borderRadius: 0,
            borderBottom: `1px solid ${theme.palette.primary.main} !important`,
            backgroundColor: `transparent !important`,
            '& .ql-picker-options': {
              backgroundColor: theme.palette.background.default,
            },
            '& .ql-tooltip-arrow': {
              display: 'none',
            },
            '& .ql-toolbar': {
              px: 1,
              '& *': {
                color: `${theme.palette.text.secondary} !important`,
                stroke: `${theme.palette.text.secondary} !important`,
                caretColor: `${theme.palette.text.secondary} !important`
              },
              '& .ql-active *, & .ql-picker *': {
                color: `${theme.palette.text.primary} !important`,
                stroke: `${theme.palette.text.primary} !important`,
                caretColor: `${theme.palette.text.primary} !important`
              }
            }
          },
        }}
      >
        <ReactQuill
          theme="bubble"
          value={editorValue}
          onChange={handleEditorChange}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          modules={{ toolbar: toolbarOptions }}
        />
      </Box>

      {touched && error && (
        <Typography
          color="error"
          variant="caption"
          sx={{
            ml: '14px',
            mt: '3px',
          }}
        >
          {error}
        </Typography>
      )}
    </FormControl>
  );
};

export default MuiEditor;
