import axios from "axios";
import { APP_DOMAIN } from "../config";

const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}


const api = axios.create({
  baseURL: `${APP_DOMAIN}/api/`,
  withCredentials: true,
});

api.interceptors.request.use(
  config => {
    config.headers['X-CSRFToken'] = sessionStorage.getItem('csrftoken') || getCookie('csrftoken');
    const accessToken = localStorage.getItem('moniCoreToken');
    if (accessToken && accessToken !== 'undefined') {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 403 && localStorage.getItem('moniCoreToken')) {
      localStorage.removeItem('moniCoreToken');
      try {
        delete originalRequest.headers.Authorization;
        return api(originalRequest);
      } catch (retryError) {
        console.error(retryError);
      }
    }
    return Promise.reject(error);
  }
);

const handleAxiosError = (error) => {
  if (error.response) {
    return error.response;
  } else if (error.request) {
    console.error('Request error:', error.request);
    return null
  } else {
    console.error('Error:', error.message);
    return null
  }
}

export const getSession = async () => {
  try {
    return await api.get('auth/session/', {
      withCredentials: true,
    });
  } catch (error) {
    return handleAxiosError(error);
  }
};

export const logoutUser = async () => {
  try {
    return await api.post('auth/session/');
  } catch (error) {
    return handleAxiosError(error);
  }
};

export const getProfile = async () => {
  try {
    return await api.get('auth/profile/');
  } catch (error) {
    return handleAxiosError(error);
  }
};

export const updateProfile = async (data, pk) => {
  try {
    return await api.patch(`auth/profile/${pk}/`, data);
  } catch (error) {
    return handleAxiosError(error);
  }
};

export const createSupportTicket = async (data) => {
  try {
    return await api.post(`support/ticket/`, data);
  } catch (error) {
    return handleAxiosError(error);
  }
};

export const createReportTicket = async (data) => {
  try {
    return await api.post(`support/report/`, data);
  } catch (error) {
    return handleAxiosError(error);
  }
};

export const getServerCategories = async () => {
  try {
    return await api.get('servers/categories/');
  } catch (error) {
    return handleAxiosError(error);
  }
};

export const getServers = async (category, tag, search, order, page = 1) => {
  try {
    const params = {
      page: page - 1,
    };
    if (category) params.category = category;
    if (tag) params.tag = tag;
    if (search) params.search = search;
    if (order) params.order = order;
    return await api.get(`servers/`, { params });
  } catch (error) {
    return handleAxiosError(error);
  }
};

export const getUserServers = async () => {
  try {
    return await api.get(`servers/user/`);
  } catch (error) {
    return handleAxiosError(error);
  }
};

export const createServer = async (data) => {
  try {
    return await api.post(`servers/server/`, data);
  } catch (error) {
    return handleAxiosError(error);
  }
};

export const editServer = async (data, id) => {
  try {
    return await api.patch(`servers/server/${id}/`, data);
  } catch (error) {
    return handleAxiosError(error);
  }
};

export const getServer = async (id) => {
  try {
    return await api.get(`servers/server/${id}/`);
  } catch (error) {
    return handleAxiosError(error);
  }
};

export const getServerStatistics = async (id, timeframe) => {
  try {
    return await api.get(`server/statistics/${id}/?timeframe=${timeframe}`);
  } catch (error) {
    return handleAxiosError(error);
  }
};

export const createServerLike = async (id) => {
  try {
    return await api.post(`server/like/${id}/`);
  } catch (error) {
    return handleAxiosError(error);
  }
};

export const createCommentLike = async (id) => {
  try {
    return await api.post(`comment/like/${id}/`);
  } catch (error) {
    return handleAxiosError(error);
  }
};

export const createComment = async (id, data, page) => {
  try {
    return await api.post(`server/comment/${id}/?page=${page - 1}`, data);
  } catch (error) {
    return handleAxiosError(error);
  }
};

export const updateComment = async (id, data, page) => {
  try {
    return await api.patch(`comment/${id}/?page=${page - 1}`, data);
  } catch (error) {
    return handleAxiosError(error);
  }
};

export const removeComment = async (id, page) => {
  try {
    return await api.delete(`comment/${id}/?page=${page - 1}`);
  } catch (error) {
    return handleAxiosError(error);
  }
};

export const getComments = async (id, page) => {
  try {
    return await api.get(`server/comments/${id}/?page=${page - 1}`);
  } catch (error) {
    return handleAxiosError(error);
  }
};
