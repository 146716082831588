import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { useTheme } from "@mui/material";

const SelectedServerEmptyPlaceholder = () => {
  const theme = useTheme();

  return (
    <Container
      maxWidth="none"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: '1em 70px 2em 70px !important',
        width: "100vw",
        height: "100vh"
      }}
    >
      <Box
        sx={{
          m: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <TravelExploreIcon
          sx={{
            fill: theme.palette.text.secondary,
            width: { xs: 50, sm: 70 },
            height: { xs: 50, sm: 70 }
          }}
        />
        <Typography
          variant="h1"
          component="h1"
          sx={{
            fontSize: { xs: 18, sm: 22 },
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.text.secondary,
            my: 2,
            textAlign: "center"
          }}
        >
          Не вдалося знайти вказаний сервер
        </Typography>
      </Box>
    </Container>
  );
};

export default SelectedServerEmptyPlaceholder;
