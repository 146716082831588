import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Box from "@mui/material/Box";


const StyledTooltip = styled(({ className, children, ...props }) => (
  <Tooltip {...props} arrow children={children} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.secondary.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 12,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.main,
  },
}));

const HoverTooltip = ({ title , children, placement }) => (
  <StyledTooltip title={title} placement={placement}>
    <Box sx={{ display: 'flex', alignItems: 'center', width: 'fit-content' }}>{children}</Box>
  </StyledTooltip>
);

export default HoverTooltip;