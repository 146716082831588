import React from "react";

const GoogleIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      version="1"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M2330 5110c-494-48-950-230-1350-538-195-150-448-432-594-662-63-99-186-351-230-471-49-134-102-340-128-499-31-195-31-565 0-760 45-276 116-498 237-745 132-269 269-460 489-681 221-220 412-357 681-489 247-121 469-192 745-237 195-31 565-31 760 0 276 45 498 116 745 237 269 132 460 269 681 489 220 221 357 412 489 681 88 179 132 296 180 476 66 253 77 351 82 767l5 372H2560v-980h1508l-29-77c-64-166-174-346-291-478-146-164-301-282-493-375-232-113-383-149-645-157-159-5-202-2-309 16-667 114-1187 632-1301 1295-27 160-27 372 0 532 114 663 634 1181 1301 1295 161 27 430 23 579-9 199-43 399-128 559-235 46-32 85-57 87-57 1 0 114 145 250 323 137 177 270 350 296 384l48 61-82 60c-325 234-718 389-1133 448-123 17-460 26-575 14z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
    </svg>
  );
}

export default GoogleIcon;
